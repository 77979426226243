import { useAuthContext } from "../hooks/useAuthContext";
import { useState } from "react";
import { useOfertaHorariaContext } from "../hooks/useOfertaHorariaContext";
import axios from "axios";
import { useConfig } from "../hooks/useConfig";
import TablaOpciones from "../components/TablaOpciones";
import { message } from "../utils/errors";
import { useWindowContext } from "../hooks/useWindowContext";
import { useToast } from "../context/ToastContext";

const Confirmar = ({ setPaso }) => {
  const { user } = useAuthContext();
  const { anio, cuat } = useConfig();
  const [disabled, setDisabled] = useState(false);
  const { small } = useWindowContext();
  const {
    opciones,
    jubilacion,
    licencia,
    renuncia,
    aumentarDed,
    dispatch,
    comentarios,
  } = useOfertaHorariaContext();
  const { showToast } = useToast();

  const handleEnviar = async (e) => {
    e.preventDefault();
    setDisabled(true);
    try {
      await axios.put(
        `/api/horarios/${anio}/${cuat}`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      dispatch({ type: "ENVIAR" });
      setPaso(4);
      window.scrollTo({
        top: 0,
      });
      showToast("Horarios enviados exitosamente", "success");
    } catch (error) {
      showToast("Error al enviar horarios. " + message(error), "error");
    } finally {
      setDisabled(false);
    }
  };
  return (
    <div className="confirmar-container">
      <form>
        <h2>Confirmar</h2>
        <div className="parent">
          <div className="preguntas">
            <div>
              <label htmlFor="jubilacion">
                ¿Está próximo/a a jubilarse?&nbsp;
              </label>
              <select id="jubilacion" value={jubilacion} disabled>
                <option value="si">Sí</option>
                <option value="no">No</option>
              </select>
            </div>
            <div>
              <label htmlFor="licencia">
                ¿Piensa pedir licencia este cuatrimestre?&nbsp;
              </label>
              <select id="licencia" value={licencia} disabled>
                <option value="si">Sí</option>
                <option value="no">No</option>
              </select>
            </div>
            <div>
              <label htmlFor="renuncia">
                ¿Piensa renunciar a alguno de sus cargos?&nbsp;
              </label>
              <select id="renuncia" value={renuncia} disabled>
                <option value="si">Sí</option>
                <option value="no">No</option>
              </select>
            </div>
            <div>
              <label htmlFor="aumentarDed">
                ¿Le gustaría aumentar su dedicación?&nbsp;
              </label>
              <select id="aumentarDed" value={aumentarDed} disabled>
                <option value="si">Sí</option>
                <option value="no">No</option>
              </select>
            </div>
          </div>
          <div className="comentarios">
            <div className="comentarios-flex">
              <label htmlFor="comentarios">Comentarios</label>
              <textarea
                id="comentarios"
                value={comentarios}
                disabled
              ></textarea>
            </div>
          </div>
        </div>
        {!small && <h3>Horarios elegidos</h3>}
        {opciones.length === 0 && <p>No se eligieron horarios.</p>}
        {opciones.length > 0 && (
          <TablaOpciones
            opciones={opciones}
            small={small}
            smallTitle={"Horarios elegidos"}
          />
        )}
        <div className="gap-1">
          <button
            onClick={() => {
              setPaso(2);
              window.scrollTo({
                top: 0,
              });
            }}
            className="btn"
            disabled={disabled}
          >
            ...Volver
          </button>{" "}
          <button onClick={handleEnviar} className="btn" disabled={disabled}>
            Enviar
          </button>
        </div>
      </form>
    </div>
  );
};

export default Confirmar;
