import React, { createContext, useState, useEffect, useReducer } from "react";
import axios from "axios";

const ConfigContext = createContext();

const configReducer = (configs, action) => {
  switch (action.type) {
    case "SET_CONFIG":
      return action.payload;
    case "AVANZAR_ESTADO_ENCUESTA":
      const { anio, cuat, estadoEncuesta } = configs;
      let nuevoAnio = anio;
      let nuevoCuat = cuat;
      if (estadoEncuesta === 3) {
        nuevoCuat = cuat === 1 ? 2 : 1;
        nuevoAnio = cuat === 1 ? anio : anio + 1;
      }
      const nuevoEstadoEncuesta = (estadoEncuesta + 1) % 4;
      let nuevoEncuestaAbierta = configs.encuestaAbierta;
      let nuevoEncuestaCerrada = configs.encuestaCerrada;
      if (nuevoEstadoEncuesta === 1) {
        nuevoEncuestaAbierta = new Date().toISOString();
      }
      if (nuevoEstadoEncuesta === 2) {
        nuevoEncuestaCerrada = new Date().toISOString();
      }
      return {
        anio: nuevoAnio,
        cuat: nuevoCuat,
        estadoEncuesta: nuevoEstadoEncuesta,
        estadoEncuestaModified: new Date().toISOString(),
        encuestaAbierta: nuevoEncuestaAbierta,
        encuestaCerrada: nuevoEncuestaCerrada,
      };
    default:
      return configs;
  }
};

const ConfigProvider = ({ children }) => {
  const [configsLoading, setConfigsLoading] = useState(true);
  const [configs, dispatch] = useReducer(configReducer, {});

  useEffect(() => {
    const fetchConfigs = async () => {
      setConfigsLoading(true);
      try {
        const response = await axios.get("/api/configs");
        const configs = Object.fromEntries(
          response.data.map((c) => [c.key, c.value])
        );
        dispatch({ type: "SET_CONFIG", payload: configs });
      } catch (error) {
        console.error("Error fetching configs:", error);
      } finally {
        setConfigsLoading(false);
      }
    };

    fetchConfigs();
  }, []);
  return (
    <ConfigContext.Provider
      value={{
        ...configs,
        configsLoading,
        dispatch,
      }}
    >
      {children}
    </ConfigContext.Provider>
  );
};

export { ConfigContext, ConfigProvider };
