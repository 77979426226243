import axios from "axios";
import { useState, useEffect } from "react";
import validator from "validator";
import { useAuthContext } from "../hooks/useAuthContext";
import { useNavigate } from "react-router-dom";
import { message } from "../utils/errors";
import { handleKeyDownNum, handlePasteNum } from "../utils/forms";
import { useWindowContext } from "../hooks/useWindowContext";
import { useToast } from "../context/ToastContext";

const Datos = ({ continuar, volver }) => {
  const { user } = useAuthContext();
  const [docente, setDocente] = useState(null);
  const navigate = useNavigate();
  const [disabled, setDisabled] = useState(false);
  const { small, medium } = useWindowContext();
  const { showToast } = useToast();

  useEffect(() => {
    if (user === null) {
      navigate("/");
      return;
    } else {
      const fetchDocente = async () => {
        try {
          const response = await axios.get("/api/docentes/", {
            headers: {
              Authorization: `Bearer ${user.token}`,
            },
          });
          let docente = { ...response.data };
          if (!docente.nacimiento) {
            docente = { ...docente, nacimiento: "", foto: "" };
          }
          setDocente(docente);
        } catch (error) {
          console.error("Error fetching docente.", message(error));
        } finally {
        }
      };
      fetchDocente();
    }
  }, [navigate, user]);

  const validateImage = (file) => {
    const maxSize = 3000 * 1024; // 1500kb
    if (file.size > maxSize) {
      showToast("La imagen no debe pesar más de 3mb", "error");
      return false;
    }
    return true;
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file && validateImage(file)) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setDocente({ ...docente, foto: reader.result });
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validator.isEmail(docente.email)) {
      showToast("Email inválido", "error");
    } else if (docente.avance > 100) {
      showToast("Porcentaje de avance inválido", "error");
    } else if (docente.celular.toString().length > 10) {
      showToast("El celular debe tener a lo sumo 10 dígitos", "error");
    } else if (
      docente.nacimiento === "" ||
      docente.celular === "" ||
      docente.email === "" ||
      docente.direccion === "" ||
      docente.localidad === "" ||
      docente.cp === "" ||
      docente.carrera === "" ||
      docente.institucion === "" ||
      docente.avance === ""
    ) {
      showToast("Los campos con asterisco son obligatorios", "error");
    } else {
      setDisabled(true);
      try {
        let update = {
          nacimiento: new Date(docente.nacimiento),
          celular: Number(docente.celular),
          email: docente.email,
          direccion: docente.direccion,
          localidad: docente.localidad,
          cp: docente.cp,
          carrera: docente.carrera,
          institucion: docente.institucion,
          avance: Number(docente.avance),
          foto: docente.foto,
        };
        await axios.put("/api/docentes/update/", update, {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        });
        continuar();
        window.scrollTo({
          top: 0,
        });
      } catch (error) {
        showToast("Error al actualizar los datos. " + message(error), "error");
      } finally {
        setDisabled(false);
      }
    }
  };

  const handleVolver = (e) => {
    e.preventDefault();
    volver();
    window.scrollTo({
      top: 0,
    });
  };

  const handleBorrar = (e) => {
    e.preventDefault();
    if (docente.foto) {
      setDocente({ ...docente, foto: "" });
      document.getElementById("foto").value = "";
    }
  };

  const getMaxNacimiento = () => {
    const today = new Date();
    today.setFullYear(today.getFullYear() - 18);
    return today.toISOString().split("T")[0];
  };
  return (
    docente && (
      <div className="datos-container">
        <h2>Verificar datos</h2>

        <form onSubmit={handleSubmit}>
          <div className="parent">
            <div className="nombre">
              <label htmlFor="nombre">Nombre</label>
              <input
                type="text"
                id="nombre"
                name="nombre"
                value={docente.nombre}
                disabled
              />
            </div>
            <div className="apellido">
              <label htmlFor="apellido">Apellido</label>
              <input
                type="text"
                id="apellido"
                name="apellido"
                value={docente.apellido}
                disabled
              />
            </div>
            <div className="dni">
              <label htmlFor="dni">DNI</label>
              <input
                type="text"
                id="dni"
                name="dni"
                value={docente.dni}
                disabled
              />
            </div>
            <div className="legajo">
              <label htmlFor="legajo">Legajo</label>
              <input
                type="text"
                id="legajo"
                name="legajo"
                value={docente.legajo}
                disabled
              />
            </div>
            <div className="cargo">
              <label htmlFor="cargo">Cargo</label>
              <input id="cargo" name="cargo" value={docente.cargo} disabled />
            </div>
            <div className="dedicacion">
              <label htmlFor="cargo">{medium ? "Ded." : "Dedicación"}</label>
              <input
                id="dedicacion"
                name="dedicacion"
                value={docente.dedicacion}
                disabled
              />
            </div>
            <div className="cantidad">
              <label htmlFor="cantidad">Cantidad</label>
              <input
                id="cantidad"
                name="cantidad"
                value={docente.cantidad}
                disabled
              />
            </div>
            <div className="nacimiento">
              <label htmlFor="nacimiento" className="required">
                {small ? "Fecha de nac." : "Fecha de nacimiento"}
              </label>
              <input
                id="nacimiento"
                name="nacimiento"
                min="1940-12-31"
                max={getMaxNacimiento()}
                type="date"
                value={docente.nacimiento.split("T")[0]}
                onChange={(e) =>
                  setDocente({ ...docente, nacimiento: e.target.value })
                }
                required
                disabled={disabled}
              />
            </div>

            <div className="email">
              <label htmlFor="email" className="required">
                Email
              </label>
              <input
                id="email"
                name="email"
                type="text"
                inputMode="email"
                maxLength={50}
                value={docente.email ? docente.email : ""}
                onChange={(e) =>
                  setDocente({ ...docente, email: e.target.value })
                }
                required
                disabled={disabled}
              />
            </div>
            <div className="celular">
              <label htmlFor="celular" className="required">
                Celular
              </label>
              <input
                type="text"
                id="celular"
                inputMode="numeric"
                value={docente.celular ? docente.celular : ""}
                onPaste={handlePasteNum}
                onKeyDown={handleKeyDownNum}
                onChange={(e) =>
                  setDocente({
                    ...docente,
                    celular: parseInt(e.target.value),
                  })
                }
                required
                disabled={disabled}
              />
            </div>

            <div className="direccion">
              <label htmlFor="direccion" className="required">
                Dirección
              </label>
              <input
                id="direccion"
                name="direccion"
                type="text"
                maxLength={50}
                value={docente.direccion ? docente.direccion : ""}
                onChange={(e) =>
                  setDocente({ ...docente, direccion: e.target.value })
                }
                required
                disabled={disabled}
              />
            </div>
            <div className="localidad">
              <label htmlFor="localidad" className="required">
                Localidad
              </label>
              <input
                id="localidad"
                name="localidad"
                type="text"
                maxLength={40}
                value={docente.localidad ? docente.localidad : ""}
                onChange={(e) =>
                  setDocente({ ...docente, localidad: e.target.value })
                }
                required
                disabled={disabled}
              />
            </div>
            <div className="cp">
              <label htmlFor="cp" className="required">
                C.P.
              </label>
              <input
                id="cp"
                name="cp"
                type="text"
                inputMode="numeric"
                maxLength={8}
                value={docente.cp ? docente.cp : ""}
                onChange={(e) => setDocente({ ...docente, cp: e.target.value })}
                required
                disabled={disabled}
              />
            </div>

            <div className="carrera">
              <label htmlFor="carrera" className="required">
                Carrera
              </label>
              <input
                id="carrera"
                name="carrera"
                type="text"
                maxLength={50}
                value={docente.carrera ? docente.carrera : ""}
                onChange={(e) =>
                  setDocente({ ...docente, carrera: e.target.value })
                }
                required
                disabled={disabled}
              />
            </div>
            <div className="institucion">
              <label htmlFor="institucion" className="required">
                Institución
              </label>
              <input
                id="institucion"
                name="institucion"
                type="text"
                maxLength={50}
                value={docente.institucion ? docente.institucion : ""}
                onChange={(e) =>
                  setDocente({ ...docente, institucion: e.target.value })
                }
                required
                disabled={disabled}
              />
            </div>
            <div className="avance">
              <label htmlFor="avance" className="required">
                {small || medium ? "%" : "Avance (%)"}
              </label>
              <input
                type="text"
                id="avance"
                name="avance"
                inputMode="numeric"
                value={docente.avance ? docente.avance : ""}
                onPaste={handlePasteNum}
                onKeyDown={handleKeyDownNum}
                onChange={(e) =>
                  setDocente({ ...docente, avance: parseInt(e.target.value) })
                }
                required
                disabled={disabled}
              />
            </div>
            <div className="foto">
              {docente.foto ? (
                <div>
                  <img
                    src={docente.foto}
                    alt="Vista previa"
                    style={{
                      maxWidth: "100%",
                      maxHeight: "300px",
                      margin: "auto",
                    }}
                  />
                </div>
              ) : (
                <svg
                  className="placeholder"
                  width="200px"
                  viewBox="0 0 133 157"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M0 124.421s4.46 5.131 7.07 7.663c3.082 2.99 6.927 6.27 9.202 7.85 5.18 3.597 8.625 5.63 13.504 7.972 9.532 4.575 18.711 7.022 30.285 8.075 8.174.743 20.893-.393 28.178-2.518 7.267-2.12 10.057-3.154 15.26-5.656 9.071-4.362 16.074-9.295 23.206-16.348 2.237-2.267 6.23-6.897 6.23-6.897s-.381-4.434-1.378-10.428c-.859-5.348-1.522-6.992-3.824-9.48-1.156-1.247-2.003-1.849-3.883-2.756-4.1-1.979-10.411-4.157-21.985-7.59-4.075-1.209-9.191-2.946-12.789-4.343l-3.264-1.268-.92-1.825a45.82 45.82 0 0 1-1.693-3.927c-.743-2.015-1.84-6.714-1.84-7.884 0-.348.984-1.577 2.615-3.266 4.19-4.336 4.851-5.291 6.631-9.585a579.4 579.4 0 0 1 1.548-3.704c.248-.582 1.133-2.1 1.966-3.37 3.009-4.595 3.625-6.615 3.832-12.553.123-3.512.051-4.576-.57-8.467-.39-2.448-.874-5.283-1.076-6.302a43.43 43.43 0 0 1-.536-3.704c-.367-4.013-2.401-8.195-6.141-12.627C85.286 6.338 79.205 2.45 72.89.781c-7.106-1.877-15.2-.333-22.097 4.215C44.568 9.1 39.04 16.129 37.714 21.624c-.154.64-.39 2.115-.523 3.28-.133 1.164-.676 4.616-1.205 7.672-.906 5.224-.956 5.81-.84 9.79.18 6.154.763 8.1 3.82 12.77.834 1.27 1.72 2.788 1.969 3.37.25.582.998 2.355 1.665 3.94 1.77 4.21 3.085 5.998 8.134 11.052.546.547.992 1.248.992 1.558 0 1.174-1.095 5.87-1.84 7.889a45.82 45.82 0 0 1-1.693 3.927l-.92 1.825-3.263 1.268c-4.288 1.665-8.596 3.088-17.287 5.708-8.21 2.475-13.425 4.32-17.198 6.085-2.194 1.026-2.903 1.516-4.154 2.872-2.294 2.487-2.964 4.149-3.836 9.515-.407 2.504-.8 4.55-.873 4.546A120.72 120.72 0 0 1 0 124.42Z" />
                </svg>
              )}
            </div>
            <div className="cargar gap-1">
              <button
                id="cargar"
                className="btn secondary"
                onClick={(e) => {
                  e.preventDefault();
                  document.getElementById("foto").click();
                }}
                disabled={disabled}
              >
                Cargar foto
              </button>
              <button
                onClick={handleBorrar}
                className="btn secondary"
                disabled={disabled || docente.foto === ""}
              >
                Eliminar foto
              </button>
              <input
                id="foto"
                name="foto"
                type="file"
                accept="image/*"
                style={{ display: "none" }}
                onChange={handleImageChange}
                disabled={disabled}
              />
            </div>
          </div>
          <div className="gap-1">
            <button
              type="button"
              className="btn"
              onClick={handleVolver}
              disabled={disabled}
            >
              ...Volver
            </button>
            <button type="submit" className="btn" disabled={disabled}>
              Guardar y continuar...
            </button>
          </div>
        </form>
      </div>
    )
  );
};

export default Datos;
