import { useState } from "react";
import { useAuthContext } from "./useAuthContext";
import { message } from "../utils/errors";
import axios from "axios";

export const useLogin = () => {
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(null);
  const { dispatch } = useAuthContext();

  const login = async (usuario, password) => {
    setIsLoading(true);
    setError(null);

    try {
      const response = await axios.post("/api/docentes/login", {
        usuario,
        password,
      });
      // save the user to local storage
      localStorage.setItem("user", JSON.stringify(response.data));

      // update the auth context
      dispatch({ type: "LOGIN", payload: response.data });
    } catch (error) {
      setError(message(error));
    } finally {
      setIsLoading(false);
    }
  };
  return { login, isLoading, error };
};
