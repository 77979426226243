// Pasa a texto un AxiosError para mostrarle al usuario
const message = (error) => {
  if (error.response && error.response.data && error.response.data.error) {
    return error.response.data.error;
  } else {
    return error.message;
  }
};

export { message };
