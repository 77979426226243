const horarios = {
  321: {
    codigo: "321",
    str: "LuJu:08-11",
    horas: ["lu-8", "lu-9", "lu-10", "ju-8", "ju-9", "ju-10"],
    dias: 2,
    hs: 3,
  },
  351: {
    codigo: 351,
    str: "MaVi:08-11",
    horas: ["ma-8", "ma-9", "ma-10", "vi-8", "vi-9", "vi-10"],
    dias: 2,
    hs: 3,
  },
  398: {
    codigo: "398",
    str: "LuJu:12-15",
    horas: ["lu-12", "lu-13", "lu-14", "ju-12", "ju-13", "ju-14"],
    dias: 2,
    hs: 3,
  },
  399: {
    codigo: "399",
    str: "LuJu:15-18",
    horas: ["lu-15", "lu-16", "lu-17", "ju-15", "ju-16", "ju-17"],
    dias: 2,
    hs: 3,
  },
  421: {
    codigo: "421",
    str: "LuJu:07-09",
    horas: ["lu-7", "lu-8", "ju-7", "ju-8"],
    dias: 2,
    hs: 2,
  },
  422: {
    codigo: "422",
    str: "LuJu:09-11",
    horas: ["lu-9", "lu-10", "ju-9", "ju-10"],
    dias: 2,
    hs: 2,
  },
  423: {
    codigo: "423",
    str: "LuJu:11-13",
    horas: ["lu-11", "lu-12", "ju-11", "ju-12"],
    dias: 2,
    hs: 2,
  },
  424: {
    codigo: "424",
    str: "LuJu:13-15",
    horas: ["lu-13", "lu-14", "ju-13", "ju-14"],
    dias: 2,
    hs: 2,
  },
  425: {
    codigo: "425",
    str: "LuJu:15-17",
    horas: ["lu-15", "lu-16", "ju-15", "ju-16"],
    dias: 2,
    hs: 2,
  },
  426: {
    codigo: "426",
    str: "LuJu:17-19",
    horas: ["lu-17", "lu-18", "ju-17", "ju-18"],
    dias: 2,
    hs: 2,
  },
  427: {
    codigo: "427",
    str: "LuJu:19-21",
    horas: ["lu-19", "lu-20", "ju-19", "ju-20"],
    dias: 2,
    hs: 2,
  },
  428: {
    codigo: "428",
    str: "LuJu:21-23",
    horas: ["lu-21", "lu-22", "ju-21", "ju-22"],
    dias: 2,
    hs: 2,
  },
  451: {
    codigo: "451",
    str: "MaVi:07-09",
    horas: ["ma-7", "ma-8", "vi-7", "vi-8"],
    dias: 2,
    hs: 2,
  },
  452: {
    codigo: "452",
    str: "MaVi:09-11",
    horas: ["ma-9", "ma-10", "vi-9", "vi-10"],
    dias: 2,
    hs: 2,
  },
  453: {
    codigo: "453",
    str: "MaVi:11-13",
    horas: ["ma-11", "ma-12", "vi-11", "vi-12"],
    dias: 2,
    hs: 2,
  },
  454: {
    codigo: "454",
    str: "MaVi:13-15",
    horas: ["ma-13", "ma-14", "vi-13", "vi-14"],
    dias: 2,
    hs: 2,
  },
  455: {
    codigo: "455",
    str: "MaVi:15-17",
    horas: ["ma-15", "ma-16", "vi-15", "vi-16"],
    dias: 2,
    hs: 2,
  },
  456: {
    codigo: "456",
    str: "MaVi:17-19",
    horas: ["ma-17", "ma-18", "vi-17", "vi-18"],
    dias: 2,
    hs: 2,
  },
  457: {
    codigo: "457",
    str: "MaVi:19-21",
    horas: ["ma-19", "ma-20", "vi-19", "vi-20"],
    dias: 2,
    hs: 2,
  },
  458: {
    codigo: "458",
    str: "MaVi:21-23",
    horas: ["ma-21", "ma-22", "vi-21", "vi-22"],
    dias: 2,
    hs: 2,
  },
  621: {
    codigo: "621",
    str: "LuJu:07-10",
    horas: ["lu-7", "lu-8", "lu-9", "ju-7", "ju-8", "ju-9"],
    dias: 2,
    hs: 3,
  },
  622: {
    codigo: "622",
    str: "LuJu:10-13",
    horas: ["lu-10", "lu-11", "lu-12", "ju-10", "ju-11", "ju-12"],
    dias: 2,
    hs: 3,
  },
  623: {
    codigo: "623",
    str: "LuJu:13-16",
    horas: ["lu-13", "lu-14", "lu-15", "ju-13", "ju-14", "ju-15"],
    dias: 2,
    hs: 3,
  },
  624: {
    codigo: "624",
    str: "LuJu:16-19",
    horas: ["lu-16", "lu-17", "lu-18", "ju-16", "ju-17", "ju-18"],
    dias: 2,
    hs: 3,
  },
  625: {
    codigo: "625",
    str: "LuJu:19-22",
    horas: ["lu-19", "lu-20", "lu-21", "ju-19", "ju-20", "ju-21"],
    dias: 2,
    hs: 3,
  },
  626: {
    codigo: "626",
    str: "LuJu:14-17",
    horas: ["lu-14", "lu-15", "lu-16", "ju-14", "ju-15", "ju-16"],
    dias: 2,
    hs: 3,
  },
  627: {
    codigo: "627",
    str: "LuJu:17-20",
    horas: ["lu-17", "lu-18", "lu-19", "ju-17", "ju-18", "ju-19"],
    dias: 2,
    hs: 3,
  },
  628: {
    codigo: "628",
    str: "LuJu:20-23",
    horas: ["lu-20", "lu-21", "lu-22", "ju-20", "ju-21", "ju-22"],
    dias: 2,
    hs: 3,
  },
  650: {
    codigo: "650",
    str: "MaVi:18-21",
    horas: ["ma-18", "ma-19", "ma-20", "vi-18", "vi-19", "vi-20"],
    dias: 2,
    hs: 3,
  },
  651: {
    codigo: "651",
    str: "MaVi:07-10",
    horas: ["ma-7", "ma-8", "ma-9", "vi-7", "vi-8", "vi-9"],
    dias: 2,
    hs: 3,
  },
  652: {
    codigo: "652",
    str: "MaVi:10-13",
    horas: ["ma-10", "ma-11", "ma-12", "vi-10", "vi-11", "vi-12"],
    dias: 2,
    hs: 3,
  },
  653: {
    codigo: "653",
    str: "MaVi:13-16",
    horas: ["ma-13", "ma-14", "ma-15", "vi-13", "vi-14", "vi-15"],
    dias: 2,
    hs: 3,
  },
  654: {
    codigo: "654",
    str: "MaVi:16-19",
    horas: ["ma-16", "ma-17", "ma-18", "vi-16", "vi-17", "vi-18"],
    dias: 2,
    hs: 3,
  },
  655: {
    codigo: "655",
    str: "MaVi:19-22",
    horas: ["ma-19", "ma-20", "ma-21", "vi-19", "vi-20", "vi-21"],
    dias: 2,
    hs: 3,
  },
  656: {
    codigo: "656",
    str: "MaVi:14-17",
    horas: ["ma-14", "ma-15", "ma-16", "vi-14", "vi-15", "vi-16"],
    dias: 2,
    hs: 3,
  },
  657: {
    codigo: "657",
    str: "MaVi:17-20",
    horas: ["ma-17", "ma-18", "ma-19", "vi-17", "vi-18", "vi-19"],
    dias: 2,
    hs: 3,
  },
  658: {
    codigo: "658",
    str: "MaVi:20-23",
    horas: ["ma-20", "ma-21", "ma-22", "vi-20", "vi-21", "vi-22"],
    dias: 2,
    hs: 3,
  },
  659: {
    codigo: "659",
    str: "MaVi:15-18",
    horas: ["ma-15", "ma-16", "ma-17", "vi-15", "vi-16", "vi-17"],
    dias: 2,
    hs: 3,
  },
  681: {
    codigo: "681",
    str: "MiSá:07-10",
    horas: ["mi-7", "mi-8", "mi-9", "sa-7", "sa-8", "sa-9"],
    dias: 2,
    hs: 3,
  },
  682: {
    codigo: "682",
    str: "MiSá:10-13",
    horas: ["mi-10", "mi-11", "mi-12", "sa-10", "sa-11", "sa-12"],
    dias: 2,
    hs: 3,
  },
  683: {
    codigo: "683",
    str: "MiSá:13-16",
    horas: ["mi-13", "mi-14", "mi-15", "sa-13", "sa-14", "sa-15"],
    dias: 2,
    hs: 3,
  },
  689: {
    codigo: "689",
    str: "Mi:20-23+Sá:13-16",
    horas: ["mi-20", "mi-21", "mi-22", "sa-13", "sa-14", "sa-15"],
    dias: 2,
    hs: 3,
  },
  721: {
    codigo: "721",
    str: "LuMiJu:07-09",
    horas: ["lu-7", "lu-8", "mi-7", "mi-8", "ju-7", "ju-8"],
    dias: 3,
    hs: 2,
  },
  722: {
    codigo: "722",
    str: "LuMiJu:09-11",
    horas: ["lu-9", "lu-10", "mi-9", "mi-10", "ju-9", "ju-10"],
    dias: 3,
    hs: 2,
  },
  723: {
    codigo: "723",
    str: "LuMiJu:11-13",
    horas: ["lu-11", "lu-12", "mi-11", "mi-12", "ju-11", "ju-12"],
    dias: 3,
    hs: 2,
  },
  724: {
    codigo: "724",
    str: "LuMiJu:13-15",
    horas: ["lu-13", "lu-14", "mi-13", "mi-14", "ju-13", "ju-14"],
    dias: 3,
    hs: 2,
  },
  725: {
    codigo: "725",
    str: "LuMiJu:15-17",
    horas: ["lu-15", "lu-16", "mi-15", "mi-16", "ju-15", "ju-16"],
    dias: 3,
    hs: 2,
  },
  726: {
    codigo: "726",
    str: "LuMiJu:17-19",
    horas: ["lu-17", "lu-18", "mi-17", "mi-18", "ju-17", "ju-18"],
    dias: 3,
    hs: 2,
  },
  727: {
    codigo: "727",
    str: "LuMiJu:19-21",
    horas: ["lu-19", "lu-20", "mi-19", "mi-20", "ju-19", "ju-20"],
    dias: 3,
    hs: 2,
  },
  728: {
    codigo: "728",
    str: "LuMiJu:21-23",
    horas: ["lu-21", "lu-22", "mi-21", "mi-22", "ju-21", "ju-22"],
    dias: 3,
    hs: 2,
  },
  731: {
    codigo: "731",
    str: "LuJuSá:07-09",
    horas: ["lu-7", "lu-8", "ju-7", "ju-8", "sa-7", "sa-8"],
    dias: 3,
    hs: 2,
  },
  732: {
    codigo: "732",
    str: "LuJuSá:09-11",
    horas: ["lu-9", "lu-10", "ju-9", "ju-10", "sa-9", "sa-10"],
    dias: 3,
    hs: 2,
  },
  733: {
    codigo: "733",
    str: "LuJuSá:11-13",
    horas: ["lu-11", "lu-12", "ju-11", "ju-12", "sa-11", "sa-12"],
    dias: 3,
    hs: 2,
  },
  734: {
    codigo: "734",
    str: "LuJuSá:13-15",
    horas: ["lu-13", "lu-14", "ju-13", "ju-14", "sa-13", "sa-14"],
    dias: 3,
    hs: 2,
  },
  735: {
    codigo: "735",
    str: "LuJuSá:15-17",
    horas: ["lu-15", "lu-16", "ju-15", "ju-16", "sa-15", "sa-16"],
    dias: 3,
    hs: 2,
  },
  736: {
    codigo: "736",
    str: "LuJuSá:17-19",
    horas: ["lu-17", "lu-18", "ju-17", "ju-18", "sa-17", "sa-18"],
    dias: 3,
    hs: 2,
  },
  737: {
    codigo: "737",
    str: "LuJuSá:19-21",
    horas: ["lu-19", "lu-20", "ju-19", "ju-20", "sa-19", "sa-20"],
    dias: 3,
    hs: 2,
  },
  738: {
    codigo: "738",
    str: "LuJuSá:21-23",
    horas: ["lu-21", "lu-22", "ju-21", "ju-22", "sa-21", "sa-22"],
    dias: 3,
    hs: 2,
  },
  751: {
    codigo: "751",
    str: "MaMiVi:07-09",
    horas: ["ma-7", "ma-8", "mi-7", "mi-8", "vi-7", "vi-8"],
    dias: 3,
    hs: 2,
  },
  752: {
    codigo: "752",
    str: "MaMiVi:09-11",
    horas: ["ma-9", "ma-10", "mi-9", "mi-10", "vi-9", "vi-10"],
    dias: 3,
    hs: 2,
  },
  753: {
    codigo: "753",
    str: "MaMiVi:11-13",
    horas: ["ma-11", "ma-12", "mi-11", "mi-12", "vi-11", "vi-12"],
    dias: 3,
    hs: 2,
  },
  754: {
    codigo: "754",
    str: "MaMiVi:13-15",
    horas: ["ma-13", "ma-14", "mi-13", "mi-14", "vi-13", "vi-14"],
    dias: 3,
    hs: 2,
  },
  755: {
    codigo: "755",
    str: "MaMiVi:15-17",
    horas: ["ma-15", "ma-16", "mi-15", "mi-16", "vi-15", "vi-16"],
    dias: 3,
    hs: 2,
  },
  756: {
    codigo: "756",
    str: "MaMiVi:17-19",
    horas: ["ma-17", "ma-18", "mi-17", "mi-18", "vi-17", "vi-18"],
    dias: 3,
    hs: 2,
  },
  757: {
    codigo: "757",
    str: "MaMiVi:19-21",
    horas: ["ma-19", "ma-20", "mi-19", "mi-20", "vi-19", "vi-20"],
    dias: 3,
    hs: 2,
  },
  758: {
    codigo: "758",
    str: "MaMiVi:21-23",
    horas: ["ma-21", "ma-22", "mi-21", "mi-22", "vi-21", "vi-22"],
    dias: 3,
    hs: 2,
  },
  921: {
    codigo: "921",
    str: "LuMiJu:07-10",
    horas: [
      "lu-7",
      "lu-8",
      "lu-9",
      "mi-7",
      "mi-8",
      "mi-9",
      "ju-7",
      "ju-8",
      "ju-9",
    ],
    dias: 3,
    hs: 3,
  },
  922: {
    codigo: "922",
    str: "LuMiJu:10-13",
    horas: [
      "lu-10",
      "lu-11",
      "lu-12",
      "mi-10",
      "mi-11",
      "mi-12",
      "ju-10",
      "ju-11",
      "ju-12",
    ],
    dias: 3,
    hs: 3,
  },
  923: {
    codigo: "923",
    str: "LuMiJu:13-16",
    horas: [
      "lu-13",
      "lu-14",
      "lu-15",
      "mi-13",
      "mi-14",
      "mi-15",
      "ju-13",
      "ju-14",
      "ju-15",
    ],
    dias: 3,
    hs: 3,
  },
  926: {
    codigo: "926",
    str: "LuMiJu:14-17",
    horas: [
      "lu-14",
      "lu-15",
      "lu-16",
      "mi-14",
      "mi-15",
      "mi-16",
      "ju-14",
      "ju-15",
      "ju-16",
    ],
    dias: 3,
    hs: 3,
  },
  927: {
    codigo: "927",
    str: "LuMiJu:17-20",
    horas: [
      "lu-17",
      "lu-18",
      "lu-19",
      "mi-17",
      "mi-18",
      "mi-19",
      "ju-17",
      "ju-18",
      "ju-19",
    ],
    dias: 3,
    hs: 3,
  },
  928: {
    codigo: "928",
    str: "LuMiJu:20-23",
    horas: [
      "lu-20",
      "lu-21",
      "lu-22",
      "mi-20",
      "mi-21",
      "mi-22",
      "ju-20",
      "ju-21",
      "ju-22",
    ],
    dias: 3,
    hs: 3,
  },
  931: {
    codigo: "931",
    str: "LuJuSá:07-10",
    horas: [
      "lu-7",
      "lu-8",
      "lu-9",
      "ju-7",
      "ju-8",
      "ju-9",
      "sa-7",
      "sa-8",
      "sa-9",
    ],
    dias: 3,
    hs: 3,
  },
  932: {
    codigo: "932",
    str: "LuJuSá:10-13",
    horas: [
      "lu-10",
      "lu-11",
      "lu-12",
      "ju-10",
      "ju-11",
      "ju-12",
      "sa-10",
      "sa-11",
      "sa-12",
    ],
    dias: 3,
    hs: 3,
  },
  951: {
    codigo: "951",
    str: "MaMiVi:07-10",
    horas: [
      "ma-7",
      "ma-8",
      "ma-9",
      "mi-7",
      "mi-8",
      "mi-9",
      "vi-7",
      "vi-8",
      "vi-9",
    ],
    dias: 3,
    hs: 3,
  },
  952: {
    codigo: "952",
    str: "MaMiVi:10-13",
    horas: [
      "ma-10",
      "ma-11",
      "ma-12",
      "mi-10",
      "mi-11",
      "mi-12",
      "vi-10",
      "vi-11",
      "vi-12",
    ],
    dias: 3,
    hs: 3,
  },
  953: {
    codigo: "953",
    str: "MaMiVi:13-16",
    horas: [
      "ma-13",
      "ma-14",
      "ma-15",
      "mi-13",
      "mi-14",
      "mi-15",
      "vi-13",
      "vi-14",
      "vi-15",
    ],
    dias: 3,
    hs: 3,
  },
  956: {
    codigo: "956",
    str: "MaMiVi:14-17",
    horas: [
      "ma-14",
      "ma-15",
      "ma-16",
      "mi-14",
      "mi-15",
      "mi-16",
      "vi-14",
      "vi-15",
      "vi-16",
    ],
    dias: 3,
    hs: 3,
  },
  957: {
    codigo: "957",
    str: "MaMiVi:17-20",
    horas: [
      "ma-17",
      "ma-18",
      "ma-19",
      "mi-17",
      "mi-18",
      "mi-19",
      "vi-17",
      "vi-18",
      "vi-19",
    ],
    dias: 3,
    hs: 3,
  },
  958: {
    codigo: "958",
    str: "MaMiVi:20-23",
    horas: [
      "ma-20",
      "ma-21",
      "ma-22",
      "mi-20",
      "mi-21",
      "mi-22",
      "vi-20",
      "vi-21",
      "vi-22",
    ],
    dias: 3,
    hs: 3,
  },
};

const horas = (opciones) => {
  if (opciones === undefined) {
    return 0;
  }
  return [
    ...new Set(
      opciones
        .map((o) => horarios[o.horario].horas.map((x) => `${o.sede}-${x}`))
        .flat()
    ),
  ].length;
};

module.exports = { horas };
