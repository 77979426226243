import React from "react";
import { Outlet } from "react-router-dom";
import { useConfig } from "../hooks/useConfig";
import { getCuat } from "../utils/strings";

// Componente de Ruta Protegida para administradores
const EncuestaRoutes = () => {
  const { anio, cuat } = useConfig();
  return (
    <>
      <div className="encuesta-routes">
        <h2>{`Encuesta de horarios - ${getCuat(
          cuat
        )} cuatrimestre de ${anio}`}</h2>
      </div>
      <Outlet />
    </>
  );
};

export default EncuestaRoutes;
