import { Navigate } from "react-router-dom";
import { useAuthContext } from "../hooks/useAuthContext";

const NotFound = () => {
  const { user } = useAuthContext();
  if (!user) {
    return <Navigate to="/" />;
  } else {
    return (
      <div className="not-found">
        <h2>404 - Página no encontrada</h2>
        <p>La página que buscás no existe.</p>
      </div>
    );
  }
};
export default NotFound;
