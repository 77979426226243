import { ConfigContext } from "../context/ConfigContext";
import { useContext } from "react";

export const useConfig = () => {
  const context = useContext(ConfigContext);

  if (!context) {
    throw Error("useConfig debe usarse dentro de ConfigContextProvider");
  }

  return context;
};
