import { useAuthContext } from "../hooks/useAuthContext";
import { useLogout } from "../hooks/useLogout";
import { useWindowContext } from "../hooks/useWindowContext";

const Navbar = () => {
  const { user } = useAuthContext();
  const { logout } = useLogout();
  const { small } = useWindowContext();
  return (
    <header>
      <div className="navbar-container">
        <h1>CBC Matemática</h1>
        {user && (
          <div className="logout">
            {!small && <span>{`(${user.nombre}) `}</span>}
            <button onClick={logout} className="btn cerrar-sesion">
              Cerrar sesión
            </button>
          </div>
        )}
      </div>
    </header>
  );
};

export default Navbar;
