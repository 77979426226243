import React, { useState, useEffect } from "react";

const Counter = ({ value = 1, disabled, onChange }) => {
  const [count, setCount] = useState(value);

  useEffect(() => {
    setCount(value);
  }, [value]);

  const handleIncrement = () => {
    if (!disabled) {
      const newCount = count + 1;
      setCount(newCount);
      onChange(newCount);
    }
  };

  const handleDecrement = () => {
    if (!disabled && count > 1) {
      const newCount = count - 1;
      setCount(newCount);
      onChange(newCount);
    }
  };

  return (
    <div className="counter">
      <button onClick={handleDecrement} disabled={disabled || count === 1}>
        &#8211;
      </button>
      <span>{count}</span>
      <button onClick={handleIncrement} disabled={disabled}>
        +
      </button>
    </div>
  );
};

export default Counter;
