import { useState, useRef, useEffect } from "react";
import { useParams } from "react-router-dom";
import TablaOpciones from "../components/TablaOpciones";
import { useConfig } from "../hooks/useConfig";
import axios from "axios";
import { useAuthContext } from "../hooks/useAuthContext";

const VerEnvio = () => {
  const { dni } = useParams();
  const { user } = useAuthContext();
  const [envioLoading, setEnvioLoading] = useState(true);
  const { anio, cuat } = useConfig();
  const [envio, setEnvio] = useState(null);
  const [enviado, setEnviado] = useState(null);
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [keyword, setKeyword] = useState("");
  const inputRef = useRef(null);

  useEffect(() => {
    if (showModal) {
      inputRef.current.focus();
    }
  }, [showModal]);

  useEffect(() => {
    const fetchEnvio = async () => {
      setEnvioLoading(true);
      try {
        const response = await axios.get(
          `/api/admin/encuesta/${anio}/${cuat}/${dni}`,
          {
            headers: {
              Authorization: `Bearer ${user.token}`,
            },
          }
        );
        setEnvio(response.data);
        setEnviado(response.data.enviada === "si");
      } catch (error) {
        // showAlert(
        //   <span>{`Error al cargar las aulas: ${error.message}`}</span>,
        //   "error"
        // );
      } finally {
        setEnvioLoading(false);
      }
    };
    fetchEnvio();
  }, [anio, cuat, dni, user.token]);

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleRevertir = async (e) => {
    setBtnDisabled(true);
    try {
      const response = await axios.put(
        `/api/admin/encuesta/revertir/${envio._id}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      setEnviado(false);
    } catch (error) {
      // showAlert(
      //   <span>{`Error al cargar las aulas: ${error.message}`}</span>,
      //   "error"
      // );
    } finally {
      setShowModal(false);
      setBtnDisabled(false);
    }
  };

  const handleForzar = async (e) => {
    setBtnDisabled(true);
    try {
      const response = await axios.put(
        `/api/admin/encuesta/forzar/${envio._id}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      setEnviado(true);
    } catch (error) {
      // showAlert(
      //   <span>{`Error al cargar las aulas: ${error.message}`}</span>,
      //   "error"
      // );
    } finally {
      setShowModal(false);
      setBtnDisabled(false);
    }
  };

  const handleConfirm = async (e) => {
    e.preventDefault();
    if (inputValue === keyword) {
      if (enviado) {
        handleRevertir();
      } else {
        handleForzar();
      }
    } else {
      alert(`Por favor, escriba "${keyword}" para confirmar.`);
    }
  };
  return envioLoading ? null : (
    <>
      <div className="ver-envio-container">
        <div>
          <form>
            <h3>{`${envio.docente.apellido}, ${envio.docente.nombre}`}</h3>
            <div className="parent">
              <div className="selects">
                <div>
                  <label htmlFor="jubilacion">
                    ¿Está próximo/a a jubilarse?
                  </label>
                  <select id="jubilacion" value={envio.jubilacion} disabled>
                    <option value="si">Sí</option>
                    <option value="no">No</option>
                  </select>
                </div>
                <div>
                  <label htmlFor="licencia">
                    ¿Piensa pedir licencia este cuatrimestre?
                  </label>
                  <select id="licencia" value={envio.licencia} disabled>
                    <option value="si">Sí</option>
                    <option value="no">No</option>
                  </select>
                </div>
                <div>
                  <label htmlFor="renuncia">
                    ¿Piensa renunciar a alguno de sus cargos?
                  </label>
                  <select id="renuncia" value={envio.renuncia} disabled>
                    <option value="si">Sí</option>
                    <option value="no">No</option>
                  </select>
                </div>
                <div>
                  <label htmlFor="aumentarDed">
                    ¿Le gustaría aumentar su dedicación?
                  </label>
                  <select id="aumentarDed" value={envio.aumentarDed} disabled>
                    <option value="si">Sí</option>
                    <option value="no">No</option>
                  </select>
                </div>
              </div>
              <div className="comentarios">
                <div className="comentarios-flex">
                  <label htmlFor="comentarios">Comentarios</label>
                  <textarea
                    id="comentarios"
                    value={envio.comentarios}
                    disabled
                  ></textarea>
                </div>
              </div>
            </div>
            <TablaOpciones opciones={envio.opciones} />
            {enviado !== null && (
              <div className="estado-envio">
                <div>
                  {`Estado: `}
                  {enviado ? (
                    <span className="enviado">Enviado</span>
                  ) : (
                    <span className="no-enviado">No enviado</span>
                  )}
                </div>
                {enviado ? (
                  <button
                    className="btn"
                    onClick={(e) => {
                      e.preventDefault();
                      setInputValue("");
                      setKeyword("revertir");
                      setShowModal(true);
                    }}
                    disabled={showModal || btnDisabled}
                  >
                    Revertir envío
                  </button>
                ) : (
                  <button
                    className="btn"
                    onClick={(e) => {
                      e.preventDefault();
                      setInputValue("");
                      setKeyword("forzar");
                      setShowModal(true);
                    }}
                    disabled={showModal || btnDisabled}
                  >
                    Forzar envío
                  </button>
                )}
              </div>
            )}
          </form>
        </div>
      </div>
      {showModal && (
        <div className="modal">
          <div className="title">
            <h3>Confirmar acción</h3>
            <button className="close-button" onClick={handleCloseModal}>
              ×
            </button>
          </div>
          <form onSubmit={handleConfirm}>
            <input
              ref={inputRef}
              type="text"
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
              placeholder={`Escriba "${keyword}"`}
              disabled={btnDisabled}
            />
            <button type="submit" className="btn" disabled={btnDisabled}>
              Confirmar
            </button>
          </form>
        </div>
      )}
    </>
  );
};

export default VerEnvio;
