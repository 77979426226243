import axios from "axios";
import { useAuthContext } from "../hooks/useAuthContext";
import { useConfig } from "../hooks/useConfig";
import { useState, useRef, useEffect } from "react";

const EstadoEncuesta = () => {
  const { estadoEncuesta: estado, dispatch } = useConfig();
  const { user } = useAuthContext();
  const [error, setError] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [avanzando, setAvanzando] = useState(false);
  const [keyword, setKeyword] = useState("");
  const [inputValue, setInputValue] = useState("");
  const inputRef = useRef(null);

  useEffect(() => {
    if (showModal) {
      inputRef.current.focus();
    }
  }, [showModal]);

  const avanzarEstadoEncuesta = async () => {
    setAvanzando(true);
    try {
      await axios.put("/api/admin/encuesta/avanzar", null, {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      });
      dispatch({ type: "AVANZAR_ESTADO_ENCUESTA" });
    } catch (error) {
      setError("Error: " + error.message);
    } finally {
      setAvanzando(false);
    }
  };

  const handleClick = (keyword) => () => {
    setKeyword(keyword);
    setInputValue("");
    setShowModal(true);
  };

  const handleConfirm = (e) => {
    e.preventDefault();
    if (inputValue === keyword) {
      setShowModal(false);
      setInputValue("");
      avanzarEstadoEncuesta();
    } else {
      alert(`Por favor, escriba "${keyword}" para confirmar.`);
    }
  };
  const handleCloseModal = () => {
    setShowModal(false);
  };
  return (
    <>
      <div className="estado-encuesta-container">
        <div className="estados-container">
          <div className={estado === 0 ? "estado-actual" : "estado-disabled"}>
            <div>
              <h3>1. Encuesta en preparación</h3>
              <p>
                Los docentes no ven ningún contenido al ingresar a la página.
              </p>
            </div>
            <div className="btns">
              <button
                className="btn"
                disabled={estado !== 0 || showModal || avanzando}
                onClick={handleClick("abrir")}
              >
                Abrir encuesta
              </button>
            </div>
          </div>
          <div className={estado === 1 ? "estado-actual" : "estado-disabled"}>
            <div>
              <h3>2. Encuesta abierta</h3>
              <p>Los docentes pueden completar y enviar la encuesta.</p>
            </div>
            <div className="btns">
              <button
                className="btn"
                disabled={estado !== 1 || showModal || avanzando}
                onClick={handleClick("cerrar")}
              >
                Cerrar encuesta
              </button>
            </div>
          </div>
          <div className={estado === 2 ? "estado-actual" : "estado-disabled"}>
            <div>
              <h3>3. Encuesta cerrada</h3>
              <p>
                Los docentes ya no pueden completar la encuesta pero ven los
                horarios que enviaron.
              </p>
            </div>
            <div className="btns">
              <button
                className="btn"
                disabled={estado !== 2 || showModal || avanzando}
                onClick={handleClick("finalizar")}
              >
                Finalizar encuesta
              </button>
            </div>
          </div>
          <div className={estado === 3 ? "estado-actual" : "estado-disabled"}>
            <div>
              <h3>4. Encuesta finalizada</h3>
              <p>Los docentes ven los horarios que les fueron asignados.</p>
            </div>
            <div className="btns">
              <button
                className="btn"
                disabled={estado !== 3 || showModal || avanzando}
                onClick={handleClick("iniciar")}
              >
                Iniciar nuevo período
              </button>
            </div>
          </div>
        </div>
      </div>
      {error && <p>{error}</p>}
      {showModal && (
        <div className="modal">
          <div className="title">
            <h3>Confirmar acción</h3>
            <button className="close-button" onClick={handleCloseModal}>
              ×
            </button>
          </div>
          <form onSubmit={handleConfirm}>
            <input
              ref={inputRef}
              type="text"
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
              placeholder={`Escriba "${keyword}"`}
            />
            <button
              type="submit"
              className="btn"
              disabled={inputValue !== keyword}
            >
              Confirmar
            </button>
          </form>
        </div>
      )}
    </>
  );
};

export default EstadoEncuesta;
