import { useState, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { useAuthContext } from "../hooks/useAuthContext";
import { message } from "../utils/errors";
import TextAlert from "../components/TextAlert";
import { handleKeyDownNum, handlePasteNum } from "../utils/forms";

const VerDocente = () => {
  const { dni } = useParams();
  const { user } = useAuthContext();
  const [docente, setDocente] = useState(null);
  const [cambios, setCambios] = useState(null);
  const [docenteLoading, setDocenteLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const [alert, setAlert] = useState({
    message: "",
    type: "",
    visible: false,
  });
  const [inputValue, setInputValue] = useState("");
  const inputRef = useRef(null);
  const [showModal, setShowModal] = useState(false);

  const handleConfirm = (e) => {
    e.preventDefault();
    if (inputValue === "blanquear") {
      setShowModal(false);
      setInputValue("");
      handleBlanquear();
    } else {
      window.alert(`Por favor, escriba "blanquear" para confirmar.`);
    }
  };
  const handleCloseModal = () => {
    setShowModal(false);
  };

  useEffect(() => {
    if (showModal) {
      inputRef.current.focus();
    }
  }, [showModal]);

  useEffect(() => {
    const fetchDocente = async () => {
      setDocenteLoading(true);
      try {
        const response = await axios.get(`/api/admin/docentes/${dni}`, {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        });
        const d = {
          ...response.data,
          dni: response.data.dni.toString(),
          cantidad: response.data.cantidad.toString(),
          legajo: response.data.legajo ? response.data.legajo.toString() : "",
        };
        setDocente(d);
        setCambios({
          apellido: d.apellido,
          nombre: d.nombre,
          legajo: d.legajo,
          cargo: d.cargo,
          dedicacion: d.dedicacion,
          cantidad: d.cantidad,
          activo: d.activo,
        });
      } catch (error) {
        setError(message(error));
      } finally {
        setDocenteLoading(false);
      }
    };
    fetchDocente();
  }, [dni, user]);

  const showAlert = (message, type) => {
    setAlert({ message, type, visible: true });
  };

  const closeAlert = () => {
    setAlert({ ...alert, visible: false });
  };

  const buscarCambios = (cambios) => {
    if (
      docente.apellido !== cambios.apellido ||
      docente.nombre !== cambios.nombre ||
      docente.legajo !== cambios.legajo ||
      docente.cargo !== cambios.cargo ||
      docente.dedicacion !== cambios.dedicacion ||
      docente.cantidad !== cambios.cantidad ||
      docente.activo !== cambios.activo
    ) {
      showAlert("Hay cambios sin guardar.", "warning");
    } else {
      closeAlert();
    }
  };

  const handleGuardar = async (e) => {
    e.preventDefault();
    if (
      cambios.legajo !== "" &&
      (cambios.legajo.length > 6 || cambios.legajo.length < 5)
    ) {
      showAlert("El legajo ingresado no es válido.", "error");
      return;
    }
    let d = {
      apellido: cambios.apellido,
      nombre: cambios.nombre,
      cargo: cambios.cargo,
      dedicacion: cambios.dedicacion,
      cantidad: parseInt(cambios.cantidad),
      activo: cambios.activo,
    };
    if (cambios.legajo !== "") {
      d.legajo = parseInt(cambios.legajo);
    }
    try {
      const response = await axios.put(`/api/admin/docentes/${dni}`, d, {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      });
      setDocente({
        ...docente,
        ...cambios,
      });
      showAlert("Cambios guardados exitosamente.", "success");
    } catch (error) {
      showAlert("Error al guardar los cambios: " + error.message, "error");
    } finally {
    }
  };

  const handleBlanquear = async () => {
    try {
      const response = await axios.put(
        `/api/admin/docentes/blanquear`,
        { dni: docente.dni },
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      showAlert("Contraseña blanqueada exitosamente.", "success");
    } catch (error) {
      showAlert("Error al blanquear la contraseña: " + error.message, "error");
    } finally {
    }
  };

  if (docenteLoading) return null;
  return error ? (
    <div>{error}</div>
  ) : (
    <>
      <div className="ver-docente">
        <h2>Editar docente</h2>
        {alert.visible && (
          <TextAlert
            type={alert.type}
            onClose={closeAlert}
            message={alert.message}
          />
        )}
        <form onSubmit={handleGuardar}>
          <div className="parent">
            <div className="estado">
              <label htmlFor="estado">Estado</label>
              <select
                value={cambios.activo}
                className={`${
                  cambios.activo === "si" ? "activo" : "no-activo"
                } ${cambios.activo !== docente.activo && "modified"}`}
                onChange={(e) => {
                  const newCambios = { ...cambios, activo: e.target.value };
                  setCambios(newCambios);
                  buscarCambios(newCambios);
                }}
                disabled={showModal}
              >
                <option value="no" className="no-activo">
                  Inactivo
                </option>
                <option value="si" className="activo">
                  Activo
                </option>
              </select>
            </div>
            <div className="nombre">
              <label htmlFor="nombre">Nombre</label>
              <input
                type="text"
                id="nombre"
                name="nombre"
                className={docente.nombre === cambios.nombre ? "" : "modified"}
                value={cambios.nombre}
                onChange={(e) => {
                  const newCambios = { ...cambios, nombre: e.target.value };
                  setCambios(newCambios);
                  buscarCambios(newCambios);
                }}
                disabled={showModal}
              />
            </div>
            <div className="apellido">
              <label htmlFor="apellido">Apellido</label>
              <input
                type="text"
                id="apellido"
                name="apellido"
                value={cambios.apellido}
                className={
                  docente.apellido === cambios.apellido ? "" : "modified"
                }
                onChange={(e) => {
                  const newCambios = { ...cambios, apellido: e.target.value };
                  setCambios(newCambios);
                  buscarCambios(newCambios);
                }}
                disabled={showModal}
              />
            </div>
            <div className="dni">
              <label htmlFor="dni">DNI</label>
              <input
                type="text"
                id="dni"
                name="dni"
                value={docente.dni}
                disabled
              />
            </div>
            <div className="legajo">
              <label htmlFor="legajo">Legajo</label>
              <input
                type="text"
                id="legajo"
                name="legajo"
                className={docente.legajo === cambios.legajo ? "" : "modified"}
                value={cambios.legajo}
                onPaste={handlePasteNum}
                onKeyDown={handleKeyDownNum}
                onChange={(e) => {
                  const newCambios = { ...cambios, legajo: e.target.value };
                  setCambios(newCambios);
                  buscarCambios(newCambios);
                }}
                disabled={showModal}
              />
            </div>
            <div className="cargo">
              <label htmlFor="cargo">Cargo</label>
              <select
                id="cargo"
                value={cambios.cargo}
                className={docente.cargo === cambios.cargo ? "" : "modified"}
                onChange={(e) => {
                  const newCambios = { ...cambios, cargo: e.target.value };
                  setCambios(newCambios);
                  buscarCambios(newCambios);
                }}
                disabled={showModal}
              >
                <option value="AY2">AY2</option>
                <option value="AY1">AY1</option>
                <option value="ADJ">ADJ</option>
                <option value="ASO">ASO</option>
                <option value="TIT">TIT</option>
              </select>
            </div>
            <div className="dedicacion">
              <label htmlFor="dedicacion">Dedicación</label>
              <select
                id="dedicacion"
                value={cambios.dedicacion}
                className={
                  docente.dedicacion === cambios.dedicacion ? "" : "modified"
                }
                onChange={(e) => {
                  const newCambios = { ...cambios, dedicacion: e.target.value };
                  setCambios(newCambios);
                  buscarCambios(newCambios);
                }}
                disabled={showModal}
              >
                <option value="SIM">SIM</option>
                <option value="S/E">S/E</option>
                <option value="S+1">S+1</option>
                <option value="EXC">EXC</option>
              </select>
            </div>
            <div className="cantidad">
              <label htmlFor="cantidad">#</label>
              <select
                id="cantidad"
                value={cambios.cantidad}
                className={
                  docente.cantidad === cambios.cantidad ? "" : "modified"
                }
                onChange={(e) => {
                  const newCambios = { ...cambios, cantidad: e.target.value };
                  setCambios(newCambios);
                  buscarCambios(newCambios);
                }}
                disabled={showModal}
              >
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
              </select>
            </div>
            <div className="nacimiento">
              <label htmlFor="nacimiento">Fecha de nacimiento</label>
              <input
                id="nacimiento"
                name="nacimiento"
                type="date"
                value={
                  docente.nacimiento ? docente.nacimiento.split("T")[0] : ""
                }
                disabled
              />
            </div>
            <div className="celular">
              <label htmlFor="celular">Celular</label>
              <input
                id="celular"
                name="celular"
                type="number"
                value={docente.celular ? docente.celular : ""}
                disabled
              />
            </div>
            <div className="email">
              <label htmlFor="email">Email</label>
              <input
                id="email"
                name="email"
                type="text"
                value={docente.email ? docente.email : ""}
                disabled
              />
            </div>

            <div className="direccion">
              <label htmlFor="direccion">Dirección</label>
              <input
                id="direccion"
                name="direccion"
                type="text"
                value={docente.direccion ? docente.direccion : ""}
                disabled
              />
            </div>
            <div className="localidad">
              <label htmlFor="localidad">Localidad</label>
              <input
                id="localidad"
                name="localidad"
                type="text"
                value={docente.localidad ? docente.localidad : ""}
                disabled
              />
            </div>
            <div className="cp">
              <label htmlFor="cp">CP</label>
              <input
                id="cp"
                name="cp"
                type="text"
                value={docente.cp ? docente.cp : ""}
                disabled
              />
            </div>

            <div className="carrera">
              <label htmlFor="carrera">Carrera</label>
              <input
                id="carrera"
                name="carrera"
                type="text"
                value={docente.carrera ? docente.carrera : ""}
                disabled
              />
            </div>
            <div className="institucion">
              <label htmlFor="institucion">Institución</label>
              <input
                id="institucion"
                name="institucion"
                type="text"
                value={docente.institucion ? docente.institucion : ""}
                disabled
              />
            </div>
            <div className="avance">
              <label htmlFor="avance">Avance (%)</label>
              <input
                id="avance"
                name="avance"
                type="number"
                value={docente.avance ? docente.avance : ""}
                disabled
              />
            </div>
            <div className="foto">
              {docente.foto && (
                <div>
                  <img
                    src={docente.foto}
                    alt="Vista previa"
                    style={{
                      maxWidth: "100%",
                      maxHeight: "300px",
                      margin: "auto",
                    }}
                  />
                </div>
              )}
            </div>
          </div>
          <div className="gap-1">
            <button onClick={handleGuardar} className="btn">
              Guardar
            </button>
            <button
              onClick={(e) => {
                e.preventDefault();
                navigate("/admin/docentes");
              }}
              className="btn secondary"
            >
              Volver
            </button>
            <button
              onClick={(e) => {
                e.preventDefault();
                setShowModal(true);
              }}
              className="btn"
            >
              Blanquear contraseña
            </button>
          </div>
        </form>
      </div>
      {showModal && (
        <div className="modal">
          <div className="title">
            <h3>Confirmar blanqueo de constraseña</h3>
            <button className="close-button" onClick={handleCloseModal}>
              ×
            </button>
          </div>
          <p>{`Confirme que desea blanquear la contraseña de ${docente.apellido}, ${docente.nombre}.`}</p>
          <form onSubmit={handleConfirm}>
            <input
              ref={inputRef}
              type="text"
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
              placeholder={`Escriba "blanquear"`}
            />
            <button
              type="submit"
              className="btn"
              disabled={inputValue !== "blanquear"}
            >
              Confirmar
            </button>
          </form>
        </div>
      )}
    </>
  );
};

export default VerDocente;
