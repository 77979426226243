import { useAuthContext } from "./useAuthContext";
import { useOfertaHorariaContext } from "./useOfertaHorariaContext";

export const useLogout = () => {
  const { dispatch } = useAuthContext();
  const { dispatch: dispatchOfertaHoraria } = useOfertaHorariaContext();

  const logout = () => {
    // remove user from storage
    localStorage.removeItem("user");

    // dispatch logout action
    dispatch({ type: "LOGOUT" });
    dispatchOfertaHoraria({ type: "LOGOUT", payload: {} });
  };

  return { logout };
};
