const horarios = {
  321: "LuJu:08-11",
  351: "MaVi:08-11",
  398: "LuJu:12-15",
  399: "LuJu:15-18",
  421: "LuJu:07-09",
  422: "LuJu:09-11",
  423: "LuJu:11-13",
  424: "LuJu:13-15",
  425: "LuJu:15-17",
  426: "LuJu:17-19",
  427: "LuJu:19-21",
  428: "LuJu:21-23",
  451: "MaVi:07-09",
  452: "MaVi:09-11",
  453: "MaVi:11-13",
  454: "MaVi:13-15",
  455: "MaVi:15-17",
  456: "MaVi:17-19",
  457: "MaVi:19-21",
  458: "MaVi:21-23",
  621: "LuJu:07-10",
  622: "LuJu:10-13",
  623: "LuJu:13-16",
  624: "LuJu:16-19",
  625: "LuJu:19-22",
  626: "LuJu:14-17",
  627: "LuJu:17-20",
  628: "LuJu:20-23",
  650: "MaVi:18-21",
  651: "MaVi:07-10",
  652: "MaVi:10-13",
  653: "MaVi:13-16",
  654: "MaVi:16-19",
  655: "MaVi:19-22",
  656: "MaVi:14-17",
  657: "MaVi:17-20",
  658: "MaVi:20-23",
  659: "MaVi:15-18",
  681: "MiSá:07-10",
  682: "MiSá:10-13",
  683: "MiSá:13-16",
  689: "Mi:20-23+Sá:13-16",
  721: "LuMiJu:07-09",
  722: "LuMiJu:09-11",
  723: "LuMiJu:11-13",
  724: "LuMiJu:13-15",
  725: "LuMiJu:15-17",
  726: "LuMiJu:17-19",
  727: "LuMiJu:19-21",
  728: "LuMiJu:21-23",
  731: "LuJuSá:07-09",
  732: "LuJuSá:09-11",
  733: "LuJuSá:11-13",
  734: "LuJuSá:13-15",
  735: "LuJuSá:15-17",
  736: "LuJuSá:17-19",
  737: "LuJuSá:19-21",
  738: "LuJuSá:21-23",
  751: "MaMiVi:07-09",
  752: "MaMiVi:09-11",
  753: "MaMiVi:11-13",
  754: "MaMiVi:13-15",
  755: "MaMiVi:15-17",
  756: "MaMiVi:17-19",
  757: "MaMiVi:19-21",
  758: "MaMiVi:21-23",
  921: "LuMiJu:07-10",
  922: "LuMiJu:10-13",
  923: "LuMiJu:13-16",
  926: "LuMiJu:14-17",
  927: "LuMiJu:17-20",
  928: "LuMiJu:20-23",
  931: "LuJuSá:07-10",
  932: "LuJuSá:10-13",
  951: "MaMiVi:07-10",
  952: "MaMiVi:10-13",
  953: "MaMiVi:13-16",
  956: "MaMiVi:14-17",
  957: "MaMiVi:17-20",
  958: "MaMiVi:20-23",
};

const horariosBis = [
  {
    codigo: 321,
    str: "LuJu:08-11",
    horas: ["lu-8", "lu-9", "lu-10", "ju-8", "ju-9", "ju-10"],
    dias: 2,
    hs: 3,
  },
  {
    codigo: 351,
    str: "MaVi:08-11",
    horas: ["ma-8", "ma-9", "ma-10", "vi-8", "vi-9", "vi-10"],
    dias: 2,
    hs: 3,
  },
  {
    codigo: 398,
    str: "LuJu:12-15",
    horas: ["lu-12", "lu-13", "lu-14", "ju-12", "ju-13", "ju-14"],
    dias: 2,
    hs: 3,
  },
  {
    codigo: 399,
    str: "LuJu:15-18",
    horas: ["lu-15", "lu-16", "lu-17", "ju-15", "ju-16", "ju-17"],
    dias: 2,
    hs: 3,
  },
  {
    codigo: 421,
    str: "LuJu:07-09",
    horas: ["lu-7", "lu-8", "ju-7", "ju-8"],
    dias: 2,
    hs: 2,
  },
  {
    codigo: 422,
    str: "LuJu:09-11",
    horas: ["lu-9", "lu-10", "ju-9", "ju-10"],
    dias: 2,
    hs: 2,
  },
  {
    codigo: 423,
    str: "LuJu:11-13",
    horas: ["lu-11", "lu-12", "ju-11", "ju-12"],
    dias: 2,
    hs: 2,
  },
  {
    codigo: 424,
    str: "LuJu:13-15",
    horas: ["lu-13", "lu-14", "ju-13", "ju-14"],
    dias: 2,
    hs: 2,
  },
  {
    codigo: 425,
    str: "LuJu:15-17",
    horas: ["lu-15", "lu-16", "ju-15", "ju-16"],
    dias: 2,
    hs: 2,
  },
  {
    codigo: 426,
    str: "LuJu:17-19",
    horas: ["lu-17", "lu-18", "ju-17", "ju-18"],
    dias: 2,
    hs: 2,
  },
  {
    codigo: 427,
    str: "LuJu:19-21",
    horas: ["lu-19", "lu-20", "ju-19", "ju-20"],
    dias: 2,
    hs: 2,
  },
  {
    codigo: 428,
    str: "LuJu:21-23",
    horas: ["lu-21", "lu-22", "ju-21", "ju-22"],
    dias: 2,
    hs: 2,
  },
  {
    codigo: 451,
    str: "MaVi:07-09",
    horas: ["ma-7", "ma-8", "vi-7", "vi-8"],
    dias: 2,
    hs: 2,
  },
  {
    codigo: 452,
    str: "MaVi:09-11",
    horas: ["ma-9", "ma-10", "vi-9", "vi-10"],
    dias: 2,
    hs: 2,
  },
  {
    codigo: 453,
    str: "MaVi:11-13",
    horas: ["ma-11", "ma-12", "vi-11", "vi-12"],
    dias: 2,
    hs: 2,
  },
  {
    codigo: 454,
    str: "MaVi:13-15",
    horas: ["ma-13", "ma-14", "vi-13", "vi-14"],
    dias: 2,
    hs: 2,
  },
  {
    codigo: 455,
    str: "MaVi:15-17",
    horas: ["ma-15", "ma-16", "vi-15", "vi-16"],
    dias: 2,
    hs: 2,
  },
  {
    codigo: 456,
    str: "MaVi:17-19",
    horas: ["ma-17", "ma-18", "vi-17", "vi-18"],
    dias: 2,
    hs: 2,
  },
  {
    codigo: 457,
    str: "MaVi:19-21",
    horas: ["ma-19", "ma-20", "vi-19", "vi-20"],
    dias: 2,
    hs: 2,
  },
  {
    codigo: 458,
    str: "MaVi:21-23",
    horas: ["ma-21", "ma-22", "vi-21", "vi-22"],
    dias: 2,
    hs: 2,
  },
  {
    codigo: 621,
    str: "LuJu:07-10",
    horas: ["lu-7", "lu-8", "lu-9", "ju-7", "ju-8", "ju-9"],
    dias: 2,
    hs: 3,
  },
  {
    codigo: 622,
    str: "LuJu:10-13",
    horas: ["lu-10", "lu-11", "lu-12", "ju-10", "ju-11", "ju-12"],
    dias: 2,
    hs: 3,
  },
  {
    codigo: 623,
    str: "LuJu:13-16",
    horas: ["lu-13", "lu-14", "lu-15", "ju-13", "ju-14", "ju-15"],
    dias: 2,
    hs: 3,
  },
  {
    codigo: 624,
    str: "LuJu:16-19",
    horas: ["lu-16", "lu-17", "lu-18", "ju-16", "ju-17", "ju-18"],
    dias: 2,
    hs: 3,
  },
  {
    codigo: 625,
    str: "LuJu:19-22",
    horas: ["lu-19", "lu-20", "lu-21", "ju-19", "ju-20", "ju-21"],
    dias: 2,
    hs: 3,
  },
  {
    codigo: 626,
    str: "LuJu:14-17",
    horas: ["lu-14", "lu-15", "lu-16", "ju-14", "ju-15", "ju-16"],
    dias: 2,
    hs: 3,
  },
  {
    codigo: 627,
    str: "LuJu:17-20",
    horas: ["lu-17", "lu-18", "lu-19", "ju-17", "ju-18", "ju-19"],
    dias: 2,
    hs: 3,
  },
  {
    codigo: 628,
    str: "LuJu:20-23",
    horas: ["lu-20", "lu-21", "lu-22", "ju-20", "ju-21", "ju-22"],
    dias: 2,
    hs: 3,
  },
  {
    codigo: 650,
    str: "MaVi:18-21",
    horas: ["ma-18", "ma-19", "ma-20", "vi-18", "vi-19", "vi-20"],
    dias: 2,
    hs: 3,
  },
  {
    codigo: 651,
    str: "MaVi:07-10",
    horas: ["ma-7", "ma-8", "ma-9", "vi-7", "vi-8", "vi-9"],
    dias: 2,
    hs: 3,
  },
  {
    codigo: 652,
    str: "MaVi:10-13",
    horas: ["ma-10", "ma-11", "ma-12", "vi-10", "vi-11", "vi-12"],
    dias: 2,
    hs: 3,
  },
  {
    codigo: 653,
    str: "MaVi:13-16",
    horas: ["ma-13", "ma-14", "ma-15", "vi-13", "vi-14", "vi-15"],
    dias: 2,
    hs: 3,
  },
  {
    codigo: 654,
    str: "MaVi:16-19",
    horas: ["ma-16", "ma-17", "ma-18", "vi-16", "vi-17", "vi-18"],
    dias: 2,
    hs: 3,
  },
  {
    codigo: 655,
    str: "MaVi:19-22",
    horas: ["ma-19", "ma-20", "ma-21", "vi-19", "vi-20", "vi-21"],
    dias: 2,
    hs: 3,
  },
  {
    codigo: 656,
    str: "MaVi:14-17",
    horas: ["ma-14", "ma-15", "ma-16", "vi-14", "vi-15", "vi-16"],
    dias: 2,
    hs: 3,
  },
  {
    codigo: 657,
    str: "MaVi:17-20",
    horas: ["ma-17", "ma-18", "ma-19", "vi-17", "vi-18", "vi-19"],
    dias: 2,
    hs: 3,
  },
  {
    codigo: 658,
    str: "MaVi:20-23",
    horas: ["ma-20", "ma-21", "ma-22", "vi-20", "vi-21", "vi-22"],
    dias: 2,
    hs: 3,
  },
  {
    codigo: 659,
    str: "MaVi:15-18",
    horas: ["ma-15", "ma-16", "ma-17", "vi-15", "vi-16", "vi-17"],
    dias: 2,
    hs: 3,
  },
  {
    codigo: 681,
    str: "MiSá:07-10",
    horas: ["mi-7", "mi-8", "mi-9", "sa-7", "sa-8", "sa-9"],
    dias: 2,
    hs: 3,
  },
  {
    codigo: 682,
    str: "MiSá:10-13",
    horas: ["mi-10", "mi-11", "mi-12", "sa-10", "sa-11", "sa-12"],
    dias: 2,
    hs: 3,
  },
  {
    codigo: 683,
    str: "MiSá:13-16",
    horas: ["mi-13", "mi-14", "mi-15", "sa-13", "sa-14", "sa-15"],
    dias: 2,
    hs: 3,
  },
  {
    codigo: 689,
    str: "Mi:20-23+Sá:13-16",
    horas: ["mi-20", "mi-21", "mi-22", "sa-13", "sa-14", "sa-15"],
    dias: 2,
    hs: 3,
  },
  {
    codigo: 721,
    str: "LuMiJu:07-09",
    horas: ["lu-7", "lu-8", "mi-7", "mi-8", "ju-7", "ju-8"],
    dias: 3,
    hs: 2,
  },
  {
    codigo: 722,
    str: "LuMiJu:09-11",
    horas: ["lu-9", "lu-10", "mi-9", "mi-10", "ju-9", "ju-10"],
    dias: 3,
    hs: 2,
  },
  {
    codigo: 723,
    str: "LuMiJu:11-13",
    horas: ["lu-11", "lu-12", "mi-11", "mi-12", "ju-11", "ju-12"],
    dias: 3,
    hs: 2,
  },
  {
    codigo: 724,
    str: "LuMiJu:13-15",
    horas: ["lu-13", "lu-14", "mi-13", "mi-14", "ju-13", "ju-14"],
    dias: 3,
    hs: 2,
  },
  {
    codigo: 725,
    str: "LuMiJu:15-17",
    horas: ["lu-15", "lu-16", "mi-15", "mi-16", "ju-15", "ju-16"],
    dias: 3,
    hs: 2,
  },
  {
    codigo: 726,
    str: "LuMiJu:17-19",
    horas: ["lu-17", "lu-18", "mi-17", "mi-18", "ju-17", "ju-18"],
    dias: 3,
    hs: 2,
  },
  {
    codigo: 727,
    str: "LuMiJu:19-21",
    horas: ["lu-19", "lu-20", "mi-19", "mi-20", "ju-19", "ju-20"],
    dias: 3,
    hs: 2,
  },
  {
    codigo: 728,
    str: "LuMiJu:21-23",
    horas: ["lu-21", "lu-22", "mi-21", "mi-22", "ju-21", "ju-22"],
    dias: 3,
    hs: 2,
  },
  {
    codigo: 731,
    str: "LuJuSá:07-09",
    horas: ["lu-7", "lu-8", "ju-7", "ju-8", "sa-7", "sa-8"],
    dias: 3,
    hs: 2,
  },
  {
    codigo: 732,
    str: "LuJuSá:09-11",
    horas: ["lu-9", "lu-10", "ju-9", "ju-10", "sa-9", "sa-10"],
    dias: 3,
    hs: 2,
  },
  {
    codigo: 733,
    str: "LuJuSá:11-13",
    horas: ["lu-11", "lu-12", "ju-11", "ju-12", "sa-11", "sa-12"],
    dias: 3,
    hs: 2,
  },
  {
    codigo: 734,
    str: "LuJuSá:13-15",
    horas: ["lu-13", "lu-14", "ju-13", "ju-14", "sa-13", "sa-14"],
    dias: 3,
    hs: 2,
  },
  {
    codigo: 735,
    str: "LuJuSá:15-17",
    horas: ["lu-15", "lu-16", "ju-15", "ju-16", "sa-15", "sa-16"],
    dias: 3,
    hs: 2,
  },
  {
    codigo: 736,
    str: "LuJuSá:17-19",
    horas: ["lu-17", "lu-18", "ju-17", "ju-18", "sa-17", "sa-18"],
    dias: 3,
    hs: 2,
  },
  {
    codigo: 737,
    str: "LuJuSá:19-21",
    horas: ["lu-19", "lu-20", "ju-19", "ju-20", "sa-19", "sa-20"],
    dias: 3,
    hs: 2,
  },
  {
    codigo: 738,
    str: "LuJuSá:21-23",
    horas: ["lu-21", "lu-22", "ju-21", "ju-22", "sa-21", "sa-22"],
    dias: 3,
    hs: 2,
  },
  {
    codigo: 751,
    str: "MaMiVi:07-09",
    horas: ["ma-7", "ma-8", "mi-7", "mi-8", "vi-7", "vi-8"],
    dias: 3,
    hs: 2,
  },
  {
    codigo: 752,
    str: "MaMiVi:09-11",
    horas: ["ma-9", "ma-10", "mi-9", "mi-10", "vi-9", "vi-10"],
    dias: 3,
    hs: 2,
  },
  {
    codigo: 753,
    str: "MaMiVi:11-13",
    horas: ["ma-11", "ma-12", "mi-11", "mi-12", "vi-11", "vi-12"],
    dias: 3,
    hs: 2,
  },
  {
    codigo: 754,
    str: "MaMiVi:13-15",
    horas: ["ma-13", "ma-14", "mi-13", "mi-14", "vi-13", "vi-14"],
    dias: 3,
    hs: 2,
  },
  {
    codigo: 755,
    str: "MaMiVi:15-17",
    horas: ["ma-15", "ma-16", "mi-15", "mi-16", "vi-15", "vi-16"],
    dias: 3,
    hs: 2,
  },
  {
    codigo: 756,
    str: "MaMiVi:17-19",
    horas: ["ma-17", "ma-18", "mi-17", "mi-18", "vi-17", "vi-18"],
    dias: 3,
    hs: 2,
  },
  {
    codigo: 757,
    str: "MaMiVi:19-21",
    horas: ["ma-19", "ma-20", "mi-19", "mi-20", "vi-19", "vi-20"],
    dias: 3,
    hs: 2,
  },
  {
    codigo: 758,
    str: "MaMiVi:21-23",
    horas: ["ma-21", "ma-22", "mi-21", "mi-22", "vi-21", "vi-22"],
    dias: 3,
    hs: 2,
  },
  {
    codigo: 921,
    str: "LuMiJu:07-10",
    horas: [
      "lu-7",
      "lu-8",
      "lu-9",
      "mi-7",
      "mi-8",
      "mi-9",
      "ju-7",
      "ju-8",
      "ju-9",
    ],
    dias: 3,
    hs: 3,
  },
  {
    codigo: 922,
    str: "LuMiJu:10-13",
    horas: [
      "lu-10",
      "lu-11",
      "lu-12",
      "mi-10",
      "mi-11",
      "mi-12",
      "ju-10",
      "ju-11",
      "ju-12",
    ],
    dias: 3,
    hs: 3,
  },
  {
    codigo: 923,
    str: "LuMiJu:13-16",
    horas: [
      "lu-13",
      "lu-14",
      "lu-15",
      "mi-13",
      "mi-14",
      "mi-15",
      "ju-13",
      "ju-14",
      "ju-15",
    ],
    dias: 3,
    hs: 3,
  },
  {
    codigo: 926,
    str: "LuMiJu:14-17",
    horas: [
      "lu-14",
      "lu-15",
      "lu-16",
      "mi-14",
      "mi-15",
      "mi-16",
      "ju-14",
      "ju-15",
      "ju-16",
    ],
    dias: 3,
    hs: 3,
  },
  {
    codigo: 927,
    str: "LuMiJu:17-20",
    horas: [
      "lu-17",
      "lu-18",
      "lu-19",
      "mi-17",
      "mi-18",
      "mi-19",
      "ju-17",
      "ju-18",
      "ju-19",
    ],
    dias: 3,
    hs: 3,
  },
  {
    codigo: 928,
    str: "LuMiJu:20-23",
    horas: [
      "lu-20",
      "lu-21",
      "lu-22",
      "mi-20",
      "mi-21",
      "mi-22",
      "ju-20",
      "ju-21",
      "ju-22",
    ],
    dias: 3,
    hs: 3,
  },
  {
    codigo: 931,
    str: "LuJuSá:07-10",
    horas: [
      "lu-7",
      "lu-8",
      "lu-9",
      "ju-7",
      "ju-8",
      "ju-9",
      "sa-7",
      "sa-8",
      "sa-9",
    ],
    dias: 3,
    hs: 3,
  },
  {
    codigo: 932,
    str: "LuJuSá:10-13",
    horas: [
      "lu-10",
      "lu-11",
      "lu-12",
      "ju-10",
      "ju-11",
      "ju-12",
      "sa-10",
      "sa-11",
      "sa-12",
    ],
    dias: 3,
    hs: 3,
  },
  {
    codigo: 951,
    str: "MaMiVi:07-10",
    horas: [
      "ma-7",
      "ma-8",
      "ma-9",
      "mi-7",
      "mi-8",
      "mi-9",
      "vi-7",
      "vi-8",
      "vi-9",
    ],
    dias: 3,
    hs: 3,
  },
  {
    codigo: 952,
    str: "MaMiVi:10-13",
    horas: [
      "ma-10",
      "ma-11",
      "ma-12",
      "mi-10",
      "mi-11",
      "mi-12",
      "vi-10",
      "vi-11",
      "vi-12",
    ],
    dias: 3,
    hs: 3,
  },
  {
    codigo: 953,
    str: "MaMiVi:13-16",
    horas: [
      "ma-13",
      "ma-14",
      "ma-15",
      "mi-13",
      "mi-14",
      "mi-15",
      "vi-13",
      "vi-14",
      "vi-15",
    ],
    dias: 3,
    hs: 3,
  },
  {
    codigo: 956,
    str: "MaMiVi:14-17",
    horas: [
      "ma-14",
      "ma-15",
      "ma-16",
      "mi-14",
      "mi-15",
      "mi-16",
      "vi-14",
      "vi-15",
      "vi-16",
    ],
    dias: 3,
    hs: 3,
  },
  {
    codigo: 957,
    str: "MaMiVi:17-20",
    horas: [
      "ma-17",
      "ma-18",
      "ma-19",
      "mi-17",
      "mi-18",
      "mi-19",
      "vi-17",
      "vi-18",
      "vi-19",
    ],
    dias: 3,
    hs: 3,
  },
  {
    codigo: 958,
    str: "MaMiVi:20-23",
    horas: [
      "ma-20",
      "ma-21",
      "ma-22",
      "mi-20",
      "mi-21",
      "mi-22",
      "vi-20",
      "vi-21",
      "vi-22",
    ],
    dias: 3,
    hs: 3,
  },
];

export default horarios;
