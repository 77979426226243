import { CargaHorariaContext } from "../context/CargaHorariaContext";
import { useContext } from "react";

export const useCargaHorariaContext = () => {
  const context = useContext(CargaHorariaContext);

  if (!context) {
    throw Error(
      "useCargaHorariaContext debe usarse dentro de CargaHorariaContextProvider"
    );
  }

  return context;
};
