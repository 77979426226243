import axios from "axios";
import { useState, useEffect, useRef } from "react";
import validator from "validator";
import { pascalCase } from "../utils/strings";
import { useNavigate } from "react-router-dom";
import { useAuthContext } from "../hooks/useAuthContext";
import TextAlert from "../components/TextAlert";
import { handlePasteNum, handleKeyDownNum } from "../utils/forms";

const NuevoDocente = () => {
  const { user } = useAuthContext();
  const navigate = useNavigate();
  const [error, setError] = useState(null);
  const [docente, setDocente] = useState({
    dni: "",
    nombre: "",
    apellido: "",
    legajo: "",
    cargo: "AY2",
    dedicacion: "SIM",
    cantidad: "1",
  });
  const [errorDNI, setErrorDNI] = useState(null);
  const [errorApellido, setErrorApellido] = useState(false);
  const [errorNombre, setErrorNombre] = useState(false);
  const [errorLegajo, setErrorLegajo] = useState(false);
  const inputRef = useRef(null);
  const [alert, setAlert] = useState({
    message: "",
    type: "",
    visible: false,
  });

  useEffect(() => {
    inputRef.current.focus();
  }, []);

  const showAlert = (message, type) => {
    setAlert({ message, type, visible: true });
  };

  const closeAlert = () => {
    setAlert({ ...alert, visible: false });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    closeAlert();
    let err = false;
    if (!validator.isInt(docente.dni, { min: 10000000, max: 100000000 })) {
      setErrorDNI("DNI inválido");
      err = true;
    }
    if (
      docente.nombre === "" ||
      !validator.isAlpha(docente.nombre, "es-ES", { ignore: " '-" })
    ) {
      setErrorNombre(true);
      err = true;
    }
    if (
      docente.apellido === "" ||
      !validator.isAlpha(docente.apellido, "es-ES", { ignore: " '-" })
    ) {
      setErrorApellido(true);
      err = true;
    }
    if (
      docente.legajo !== "" &&
      !validator.isInt(docente.legajo, { min: 10000, max: 1000000 })
    ) {
      setErrorLegajo(true);
      err = true;
    }
    if (!err) {
      try {
        let d = {
          dni: Number(docente.dni),
          apellido: pascalCase(docente.apellido),
          nombre: pascalCase(docente.nombre),
          cargo: docente.cargo,
          dedicacion: docente.dedicacion,
          cantidad: parseInt(docente.cantidad),
        };
        const legajo = Number(docente.legajo);
        if (legajo !== 0) {
          d.legajo = legajo;
        }
        const response = await axios.post("/api/admin/docentes", d, {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        });
        setError(null);
        navigate(`/admin/docentes`, {
          state: {
            alert: {
              type: "success",
              message: `El docente ${d.nombre} ${d.apellido} fue agregado exitosamente.`,
            },
          },
        });
      } catch (error) {
        if (
          error.response &&
          error.response.data &&
          error.response.data.error &&
          error.response.data.error.includes("E11000")
        ) {
          setErrorDNI("El DNI ya está en uso");
        } else {
          const err =
            error.response && error.response.data && error.response.data.error
              ? error.response.data.error
              : error.message;
          showAlert("Error al agregar docente: " + err, "error");
        }
      }
    }
  };
  return (
    <div className="nuevo-docente">
      <h2>Nuevo docente</h2>
      {alert.visible && (
        <TextAlert
          type={alert.type}
          onClose={closeAlert}
          message={alert.message}
        />
      )}
      <form onSubmit={handleSubmit}>
        <div className="parent">
          <div className="dni">
            <div className="label-container">
              <label htmlFor="dni" className="required">
                DNI
              </label>
              <span className="error">{errorDNI}</span>
            </div>
            <input
              type="text"
              id="dni"
              ref={inputRef}
              value={docente.dni}
              onPaste={handlePasteNum}
              onKeyDown={handleKeyDownNum}
              onChange={(e) => {
                setErrorDNI(null);
                setDocente({ ...docente, dni: e.target.value });
              }}
              required
            />
          </div>
          <div className="apellido">
            <div className="label-container">
              <label htmlFor="apellido" className="required">
                Apellido
              </label>
              <span className="error">
                {errorApellido && "Apellido inválido"}
              </span>
            </div>
            <input
              id="apellido"
              value={docente.value}
              onChange={(e) => {
                setErrorApellido(false);
                setDocente({ ...docente, apellido: e.target.value });
              }}
              required
            />
            <span className="info-campo">
              Solo se permiten letras y espacios
            </span>
          </div>
          <div className="nombre">
            <div className="label-container">
              <label htmlFor="nombre" className="required">
                Nombre
              </label>
              <span className="error">{errorNombre && "Nombre inválido"}</span>
            </div>
            <input
              id="nombre"
              value={docente.nombre}
              onChange={(e) => {
                setErrorNombre(false);
                setDocente({ ...docente, nombre: e.target.value });
              }}
              required
            />
            <span className="info-campo">
              Solo se permiten letras y espacios
            </span>
          </div>
          <div className="legajo">
            <div className="label-container">
              <label htmlFor="legajo">Legajo</label>
              <span className="error">{errorLegajo && "Legajo inválido"}</span>
            </div>
            <input
              id="legajo"
              value={docente.legajo}
              onPaste={handlePasteNum}
              type="text"
              onKeyDown={handleKeyDownNum}
              onChange={(e) => {
                setErrorLegajo(false);
                setDocente({ ...docente, legajo: e.target.value });
              }}
            />
          </div>
          <div className="cargo">
            <label htmlFor="cargo" className="required">
              Cargo
            </label>
            <select
              id="cargo"
              value={docente.cargo}
              onChange={(e) => {
                setDocente({ ...docente, cargo: e.target.value });
              }}
            >
              <option value="AY2">AY2</option>
              <option value="AY1">AY1</option>
              <option value="JTP">JTP</option>
              <option value="ADJ">ADJ</option>
              <option value="ASO">ASO</option>
              <option value="TIT">TIT</option>
            </select>
          </div>
          <div className="dedicacion">
            <label htmlFor="dedicacion" className="required">
              Dedicación
            </label>
            <select
              id="dedicacion"
              value={docente.dedicacion}
              onChange={(e) => {
                setDocente({ ...docente, dedicacion: e.target.value });
              }}
            >
              <option value="SIM">SIM</option>
              <option value="S/E">S/E</option>
              <option value="S+1">S+1</option>
              <option value="EXC">EXC</option>
            </select>
          </div>
          <div className="cantidad">
            <label htmlFor="cantidad" className="required">
              #
            </label>
            <select
              id="cantidad"
              value={docente.cantidad}
              onChange={(e) => {
                setDocente({ ...docente, cantidad: e.target.value });
              }}
            >
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
            </select>
          </div>
        </div>
        <div className="gap-1">
          <button type="submit" className="btn">
            Agregar docente
          </button>
          <button
            onClick={(e) => {
              e.preventDefault();
              navigate("/admin/docentes");
            }}
            className="btn secondary"
          >
            Volver
          </button>
        </div>
        {error && <div>{error}</div>}
      </form>
    </div>
  );
};

export default NuevoDocente;
