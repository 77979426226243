import React, { createContext, useReducer } from "react";
import { compareAulas } from "../utils/aulas";

const AulaContext = createContext();

export const aulaReducer = (aulas, action) => {
  switch (action.type) {
    case "SET_AULAS":
      return action.payload.sort(compareAulas);
    case "UPDATE_AULA":
      return aulas
        .map((aula) =>
          aula._id === action.payload._id ? action.payload : aula
        )
        .sort(compareAulas);
    case "CREATE_AULA":
      return [...aulas, action.payload].sort(compareAulas);
    case "DELETE_AULA":
      return aulas
        .filter((aula) => aula._id !== action.payload._id)
        .sort(compareAulas);
    default:
      return aulas;
  }
};

const AulaContextProvider = ({ children }) => {
  const [aulas, dispatchAula] = useReducer(aulaReducer, null);

  return (
    <AulaContext.Provider value={{ aulas, dispatchAula }}>
      {children}
    </AulaContext.Provider>
  );
};

export { AulaContext, AulaContextProvider };
