import { Navigate } from "react-router-dom";
import { useLogin } from "../hooks/useLogin";
import { useAuthContext } from "../hooks/useAuthContext";
import TextAlert from "../components/TextAlert";
import { useEffect, useRef, useState } from "react";
import { useToast } from "../context/ToastContext";

const Login = () => {
  const { user, expired } = useAuthContext();
  const [usuario, setUsuario] = useState("");
  const [password, setPassword] = useState("");
  const { login, error, isLoading } = useLogin();
  const inputRef = useRef(null);
  const { showToast } = useToast();

  useEffect(() => {
    try {
      inputRef.current.focus();
    } catch (e) {}
  }, []);

  useEffect(() => {
    if (error) {
      showToast(error, "error");
    }
  }, [error]);

  useEffect(() => {
    if (expired) {
      showToast("La sesión expiró", "error");
    }
  }, [expired]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    await login(usuario, password);
  };
  return user ? (
    <Navigate to="/" />
  ) : (
    <div className="login">
      <form onSubmit={handleSubmit}>
        <div>
          <label htmlFor="usuario">Usuario</label>
          <input
            type="text"
            inputMode="numeric"
            id="usuario"
            onChange={(e) => setUsuario(e.target.value)}
            value={usuario}
            ref={inputRef}
            disabled={isLoading}
            required
          ></input>
        </div>
        <div>
          <label htmlFor="password">Contraseña</label>
          <input
            type="password"
            id="password"
            onChange={(e) => setPassword(e.target.value)}
            value={password}
            disabled={isLoading}
            required
          ></input>
        </div>

        <button disabled={isLoading} className="btn">
          Acceder
        </button>
      </form>
    </div>
  );
};

export default Login;
