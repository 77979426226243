import { useState } from "react";
import Pasos from "../components/Pasos";
import Inicio from "./Inicio";
import Datos from "./Datos";
import Elegir from "./Elegir";
import Confirmar from "./Confirmar";
import EncuestaEnviada from "./EncuestaEnviada";
import { useOfertaHorariaContext } from "../hooks/useOfertaHorariaContext";
import PasosContainer from "../components/PasosContainer";

const EncuestaAbierta = () => {
  const { ofertaEnviada, tieneCargaAsignada } = useOfertaHorariaContext();
  const [paso, setPaso] = useState(ofertaEnviada ? 4 : 0);

  return tieneCargaAsignada ? (
    <>
      <PasosContainer>
        <Pasos paso={paso} />
      </PasosContainer>
      {paso === 0 && (
        <Inicio
          continuar={() => {
            setPaso(1);
          }}
        />
      )}
      {paso === 1 && (
        <Datos
          continuar={() => {
            setPaso(2);
          }}
          volver={() => {
            setPaso(0);
          }}
        />
      )}
      {paso === 2 && (
        <Elegir
          continuar={() => {
            setPaso(3);
          }}
          volver={() => {
            setPaso(1);
          }}
        />
      )}
      {paso === 3 && <Confirmar setPaso={setPaso} />}
      {paso === 4 && <EncuestaEnviada />}
    </>
  ) : (
    <>
      <PasosContainer />
      <div className="inicio-container">
        <h2>Encuesta abierta</h2>
        <p>
          No se encontró su carga horaria para el próximo cuatrimestre. Si tiene
          que cumplir horas el próximo cuatrimestre, comuníquese con la comisión
          de distribución a{" "}
          <a href="mailto:distribucionmatecbc@gmail.com">
            distribucionmatecbc@gmail.com
          </a>
          .
        </p>
      </div>
    </>
  );
};

export default EncuestaAbierta;
