import { useEffect, useState } from "react";
import axios from "axios";
import { useAuthContext } from "../hooks/useAuthContext";
import { useConfig } from "../hooks/useConfig";
import EncuestaFinalizada from "../pages/EncuestaFinalizada";
import EncuestaCerrada from "../pages/EncuestaCerrada";
import EncuestaAbierta from "../pages/EncuestaAbierta";
import PreparandoEncuesta from "./PreparandoEncuesta";
import { useOfertaHorariaContext } from "../hooks/useOfertaHorariaContext";
import { Navigate } from "react-router-dom";
import Error from "./Error";

const Encuesta = () => {
  const { user } = useAuthContext();
  const { anio, cuat, estadoEncuesta: estado } = useConfig();
  const { dispatch } = useOfertaHorariaContext();
  const [loadingSetup, setLoadingSetup] = useState(true);
  const [passwordInsegura, setPasswordInsegura] = useState(null);

  // Se fija si el docente tiene carga asignada y cuántas horas.
  // Si ya completó la encuesta, trae las respuestas enviadas.
  useEffect(() => {
    setLoadingSetup(true);
    const fetchSetup = async () => {
      try {
        const response = await axios.get(`/api/setup/${anio}/${cuat}`, {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        });
        dispatch({ type: "SETUP", payload: response.data });
      } catch (error) {
        console.log(error);
        if (error.response?.data?.error === "La contraseña no es segura.") {
          setPasswordInsegura(true);
        }
      } finally {
        setLoadingSetup(false);
      }
    };
    fetchSetup();
  }, [anio, cuat, user, dispatch]);

  if (loadingSetup) return null;

  if (passwordInsegura)
    return (
      <Navigate
        to="/cambiar-password"
        state={{
          toast: {
            type: "error",
            message: "Debe cambiar su contraseña",
          },
        }}
      />
    );

  return estado === 3 ? (
    <EncuestaFinalizada />
  ) : estado === 2 ? (
    <EncuestaCerrada />
  ) : estado === 1 ? (
    <EncuestaAbierta />
  ) : estado === 0 ? (
    <PreparandoEncuesta />
  ) : (
    <Error error="Hubo un error al cargar los datos de la encuesta." />
  );
};

export default Encuesta;
