import { small, useWindowContext } from "../hooks/useWindowContext";

const Pasos = ({ paso }) => {
  const { small } = useWindowContext();
  return paso === 4 ? null : (
    <div className="pasos">
      <span className={paso !== 0 ? "inactivo" : ""}>Inicio</span>
      <span className="inactivo">{" > "}</span>
      <span className={paso !== 1 ? "inactivo" : ""}>
        {small ? "Datos" : "Verificar datos"}
      </span>
      <span className="inactivo">{" > "}</span>
      <span className={paso !== 2 ? "inactivo" : ""}>
        {small ? "Horarios" : "Elegir horarios"}
      </span>
      <span className="inactivo">{" > "}</span>
      <span className={paso !== 3 ? "inactivo" : ""}>Confirmar</span>
    </div>
  );
};

export default Pasos;
