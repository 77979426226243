const handlePasteNum = (e) => {
  e.preventDefault(); // Prevenir la acción por defecto de pegar

  // Obtener el texto del portapapeles
  const clipboardData = e.clipboardData || window.clipboardData;
  let pastedData = clipboardData.getData("text");

  // Reemplazar todos los caracteres no numéricos
  pastedData = pastedData.replace(/\D/g, "");

  // Insertar el texto modificado en el input
  const input = e.target;
  const start = input.selectionStart;
  const end = input.selectionEnd;
  input.value =
    input.value.slice(0, start) + pastedData + input.value.slice(end);

  // Ajustar la posición del cursor
  input.setSelectionRange(start + pastedData.length, start + pastedData.length);
};

const allowedKeys = [
  "0",
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "ArrowLeft",
  "ArrowRight",
  "End",
  "Home",
  "Backspace",
  "Delete",
  "Tab",
];

const handleKeyDownNum = (e) => {
  const key = e.key.toLowerCase();
  const isCtrl = e.ctrlKey || e.metaKey; // Para soportar Mac (Cmd)

  // Permitir cortar (Ctrl+X), copiar (Ctrl+C) y pegar (Ctrl+V)
  if (
    (isCtrl && key === "x") ||
    (isCtrl && key === "c") ||
    (isCtrl && key === "v") ||
    (isCtrl && key === "z") ||
    (isCtrl && key === "y")
  ) {
    return; // No hacer preventDefault
  }

  // Prevenir la acción por defecto si la tecla no está permitida
  if (!allowedKeys.includes(e.key)) {
    e.preventDefault();
  }
};

export { handlePasteNum, handleKeyDownNum };
