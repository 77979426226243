import { Navigate, Outlet, NavLink } from "react-router-dom";
import { useAuthContext } from "../hooks/useAuthContext";

// Componente de Ruta Protegida para administradores
const LeftPanel = () => {
  const { user } = useAuthContext();

  if (!user) {
    return <Navigate to="/" replace />;
  }
  return (
    <div className="panel-container">
      {user.rol === "ADMIN" && (
        <nav className="panel big">
          <ul>
            <li className="single">
              <NavLink
                to="/encuesta"
                className={({ isActive }) => (isActive ? "activo" : "")}
              >
                Encuesta
              </NavLink>
            </li>
            <li className="single">
              <NavLink
                to="/cambiar-password"
                className={({ isActive }) => (isActive ? "activo" : "")}
              >
                Cambiar contraseña
              </NavLink>
            </li>

            <li className="single">
              <NavLink to="/admin/docentes">Docentes</NavLink>
            </li>
            <li>
              <NavLink to="/admin/encuesta">Administrar encuesta</NavLink>
              <ul>
                <li>
                  <NavLink to="/admin/encuesta/aulas">Aulas</NavLink>
                </li>
                <li>
                  <NavLink to="/admin/encuesta/carga">Cargas horarias</NavLink>
                </li>
                <li>
                  <NavLink to="/admin/encuesta/ver-envios">
                    Estadísticas
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/admin/encuesta/tt">TachaTacha</NavLink>
                </li>
                <li>
                  <NavLink to="/admin/encuesta/horarios-asignados">
                    Asignar horarios
                  </NavLink>
                </li>
              </ul>
            </li>
          </ul>
        </nav>
      )}
      <div className={user.rol === "ADMIN" ? "content" : "container"}>
        <Outlet />
      </div>
    </div>
  );
};

export default LeftPanel;
