import { AulaContext } from "../context/AulaContext";
import { useContext } from "react";

export const useAulaContext = () => {
  const context = useContext(AulaContext);

  if (!context) {
    throw Error("useAulaContext debe usarse dentro de AulaContextProvider");
  }

  return context;
};
