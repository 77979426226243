import { useState, useRef } from "react";
import axios from "axios";
import { useEffect } from "react";
import { useAuthContext } from "../hooks/useAuthContext";
import { useLocation, useNavigate } from "react-router-dom";
import { useToast } from "../context/ToastContext";

const CambiarPassword = () => {
  const { user } = useAuthContext();
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword1, setNewPassword1] = useState("");
  const [newPassword2, setNewPassword2] = useState("");
  const [disabled, setDisabled] = useState(false);
  const location = useLocation();
  const inputRef = useRef(null);
  const navigate = useNavigate();
  const { showToast } = useToast();

  useEffect(() => {
    inputRef.current.focus();
  }, []);

  useEffect(() => {
    if (location.state?.toast) {
      const { message, type } = location.state.toast;
      showToast(message, type);
      // Limpiar el estado para que no se muestre nuevamente
      window.history.replaceState({}, document.title);
    }
  }, [location.state]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setDisabled(true);
    try {
      if (newPassword1 !== newPassword2) {
        throw new Error("Las contraseñas no coinciden.");
      }
      const body = { currentPassword, newPassword: newPassword1 };
      await axios.post("/api/docentes/cambiar-password", body, {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      });
      showToast("La contraseña se cambió exitosamente", "success");
      setCurrentPassword("");
      setNewPassword1("");
      setNewPassword2("");
      navigate("/");
    } catch (error) {
      const m =
        error.message === "Las contraseñas no coinciden"
          ? "Las contraseñas no coinciden"
          : error.response?.data?.error
          ? error.response?.data?.error
          : error.message;
      showToast(m, "error");
    } finally {
      setDisabled(false);
    }
  };
  return (
    <div className="cambiar-pass-container">
      <h2>Cambiar contraseña</h2>
      <form onSubmit={handleSubmit}>
        <p>
          La nueva contraseña debe tener al menos 8 caracteres e incluir al
          menos una mayúscula, una minúscula y un número.
        </p>
        <div className="parent">
          <div className="actual">
            <label htmlFor="current-password" className="required">
              Contraseña actual
            </label>

            <input
              type="password"
              id="current-password"
              name="currentPassword"
              value={currentPassword}
              onChange={(e) => {
                setCurrentPassword(e.target.value);
              }}
              required
              ref={inputRef}
              disabled={disabled}
            />
          </div>

          <div className="nueva">
            <label htmlFor="new-password" className="required">
              Nueva contraseña
            </label>
            <span className="error">{}</span>

            <input
              type="password"
              id="new-password"
              name="newPassword"
              value={newPassword1}
              onChange={(e) => {
                setNewPassword1(e.target.value);
              }}
              required
              disabled={disabled}
            />
          </div>
          <div className="confirmar">
            <label htmlFor="confirm-password" className="required">
              Confirmar nueva contraseña
            </label>
            <span className="error">{}</span>

            <input
              type="password"
              id="confirm-password"
              name="confirmPassword"
              value={newPassword2}
              onChange={(e) => {
                setNewPassword2(e.target.value);
              }}
              required
              disabled={disabled}
            />
          </div>
        </div>
        <button type="submit" className="btn" disabled={disabled}>
          Cambiar contraseña
        </button>
      </form>
    </div>
  );
};

export default CambiarPassword;
