import { useConfig } from "../hooks/useConfig";
import { useWindowContext } from "../hooks/useWindowContext";

const PasosContainer = ({ children }) => {
  const { anio, cuat } = useConfig();
  const { small } = useWindowContext();

  return (
    <div className="pasos-container">
      <h2>{`Horarios – ${cuat === 1 ? "1°" : "2°"} ${
        small ? "cuat." : "cuatrimestre de"
      } ${anio}`}</h2>
      {children}
    </div>
  );
};

export default PasosContainer;
