import { useState } from "react";
import horarios from "../constants/horarios";

const disabledS = { pointerEvents: "none", color: "#bfbfbf" };
const enabledS = { cursor: "pointer", color: "black" };
const hoverS = {
  cursor: "pointer",
  color: "#4CA94C",
  textDecoration: "underline",
};

const Aula = ({ horario, handleClick, disabled }) => {
  const [hover, setHover] = useState(false);
  return (
    <div>
      <span
        onClick={handleClick}
        style={disabled ? disabledS : hover ? hoverS : enabledS}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        {horarios[horario]}
      </span>
    </div>
  );
};

export default Aula;
