import PasosContainer from "../components/PasosContainer";
import TablaOpciones from "../components/TablaOpciones";
import { useOfertaHorariaContext } from "../hooks/useOfertaHorariaContext";
import { useWindowContext } from "../hooks/useWindowContext";

const EncuestaCerrada = () => {
  const {
    jubilacion,
    licencia,
    renuncia,
    aumentarDed,
    comentarios,
    opciones,
    ofertaEnviada,
  } = useOfertaHorariaContext();
  const { small } = useWindowContext();

  return (
    <>
      <PasosContainer />
      <div className="confirmar-container">
        <h2>Encuesta cerrada</h2>
        {ofertaEnviada ? (
          <>
            <h3>Respuestas enviadas</h3>
            <form>
              <div className="parent">
                <div className="preguntas">
                  <div>
                    <label htmlFor="jubilacion">
                      ¿Está próximo/a a jubilarse?&nbsp;
                    </label>
                    <select id="jubilacion" value={jubilacion} disabled>
                      <option value="si">Sí</option>
                      <option value="no">No</option>
                    </select>
                  </div>
                  <div>
                    <label htmlFor="licencia">
                      ¿Piensa pedir licencia este cuatrimestre?&nbsp;
                    </label>
                    <select id="licencia" value={licencia} disabled>
                      <option value="si">Sí</option>
                      <option value="no">No</option>
                    </select>
                  </div>
                  <div>
                    <label htmlFor="renuncia">
                      ¿Piensa renunciar a alguno de sus cargos?&nbsp;
                    </label>
                    <select id="renuncia" value={renuncia} disabled>
                      <option value="si">Sí</option>
                      <option value="no">No</option>
                    </select>
                  </div>
                  <div>
                    <label htmlFor="aumentarDed">
                      ¿Le gustaría aumentar su dedicación?&nbsp;
                    </label>
                    <select id="aumentarDed" value={aumentarDed} disabled>
                      <option value="si">Sí</option>
                      <option value="no">No</option>
                    </select>
                  </div>
                </div>
                <div className="comentarios">
                  <div className="comentarios-flex">
                    <label htmlFor="comentarios">Comentarios</label>
                    <textarea
                      id="comentarios"
                      value={comentarios}
                      disabled
                    ></textarea>
                  </div>
                </div>
              </div>
              {!small && <h3>Horarios enviados</h3>}
              {opciones.length === 0 && <p>No se enviaron horarios.</p>}
              {opciones.length > 0 && (
                <TablaOpciones
                  opciones={opciones}
                  small={small}
                  smallTitle={"Horarios enviados"}
                />
              )}
            </form>
          </>
        ) : (
          <p>No se realizó ningún envío.</p>
        )}
      </div>
    </>
  );
};

export default EncuestaCerrada;
