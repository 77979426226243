import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { ConfigProvider } from "./context/ConfigContext";
import { AuthContextProvider } from "./context/AuthContext";
import { OfertaHorariaContextProvider } from "./context/OfertaHorariaContext";
import { AulaContextProvider } from "./context/AulaContext";
import { CargaHorariaContextProvider } from "./context/CargaHorariaContext";
import { WindowContextProvider } from "./context/WindowContext";
import { ToastProvider } from "./context/ToastContext";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <WindowContextProvider>
      <ToastProvider>
        <ConfigProvider>
          <AuthContextProvider>
            <AulaContextProvider>
              <CargaHorariaContextProvider>
                <OfertaHorariaContextProvider>
                  <App />
                </OfertaHorariaContextProvider>
              </CargaHorariaContextProvider>
            </AulaContextProvider>
          </AuthContextProvider>
        </ConfigProvider>
      </ToastProvider>
    </WindowContextProvider>
  </React.StrictMode>
);
