import { useState, useEffect } from "react";
import { useConfig } from "../hooks/useConfig";
import { useAuthContext } from "../hooks/useAuthContext";
import axios from "axios";
import sedes from "../constants/sedes";
import horarios from "../constants/horarios";
import { materiasOfertaHoraria } from "../constants/materias";
import PasosContainer from "../components/PasosContainer";
import { useWindowContext } from "../hooks/useWindowContext";

const materias = materiasOfertaHoraria;

const EncuestaFinalizada = () => {
  const { anio, cuat } = useConfig();
  const { user } = useAuthContext();
  const [horariosAsignados, setHorariosAsignados] = useState(null);
  const [horariosAsignadosLoading, setHorariosAsignadosLoading] =
    useState(true);
  const [error, setError] = useState(null);
  const { small } = useWindowContext();

  const reqHeader = {
    headers: {
      Authorization: `Bearer ${user.token}`,
    },
  };

  useEffect(() => {
    const fetchHorariosAsignados = async () => {
      setHorariosAsignadosLoading(true);
      setError(null);
      try {
        const response = await axios.get(
          `/api/horarios-asignados/${anio}/${cuat}`,
          reqHeader
        );
        setHorariosAsignados(response.data);
      } catch (error) {
        setError("Error al cargar los horarios asignados:", error.message);
      } finally {
        setHorariosAsignadosLoading(false);
      }
    };
    fetchHorariosAsignados();
  }, [anio, cuat, setHorariosAsignados]);

  return (
    <>
      <PasosContainer />
      <div className="encuesta-finalizada-container">
        <h2>Horarios asignados</h2>
        {error && (
          <p>
            Hubo un error al cargar los horarios asignados. Vuelva a intentar en
            otro momento.
          </p>
        )}
        {!horariosAsignadosLoading &&
          horariosAsignados &&
          !small &&
          horariosAsignados.length > 0 && (
            <table>
              <thead>
                <tr>
                  <th>Sede</th>
                  <th>Materia</th>
                  <th>Horario</th>
                  <th>Horas</th>
                  <th>Comentario</th>
                </tr>
              </thead>
              <tbody>
                {horariosAsignados.map((h) => (
                  <tr key={`${h._id}`}>
                    <td>{sedes[h.sede]}</td>
                    <td>{materias[h.materia]}</td>
                    <td>{horarios[h.horario]}</td>
                    <td>{h.horas}</td>
                    <td>{h.comentario}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        {!horariosAsignadosLoading &&
          horariosAsignados &&
          small &&
          horariosAsignados.length > 0 && (
            <table>
              <thead>
                <tr>
                  <th>Materia</th>
                  <th>Horas</th>
                  <th>Comentario</th>
                </tr>
              </thead>
              <tbody>
                {horariosAsignados.map((h) => (
                  <tr key={`${h._id}`}>
                    <td className="materia">
                      <span>{sedes[h.sede]}</span>
                      <span>{materias[h.materia]}</span>
                      <span>{horarios[h.horario]}</span>
                    </td>
                    <td>{h.horas}</td>
                    <td>{h.comentario}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        {!horariosAsignadosLoading &&
          (!horariosAsignados || horariosAsignados.length === 0) && (
            <p>
              No se encontraron horarios asignados. Si tiene que cumplir horas
              el próximo cuatrimestre, comuníquese con la comisión de
              distribución a{" "}
              <a href="mailto:distribucionmatecbc@gmail.com">
                distribucionmatecbc@gmail.com
              </a>
              .
            </p>
          )}
      </div>
    </>
  );
};

export default EncuestaFinalizada;
