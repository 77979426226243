import React, { useEffect, useState } from "react";
import axios from "axios";
import { useAuthContext } from "../hooks/useAuthContext";
import { useConfig } from "../hooks/useConfig";
import { useCargaHorariaContext } from "../hooks/useCargaHorariaContext";
import Alert from "../components/Alert";
import { compareDocentes } from "../utils/docentes";

const esCarga = (str) => {
  const num = Number(str);
  return str !== "" && Number.isInteger(num) && num >= 0 && num <= 20;
};

const ImportarCargaHoraria = () => {
  const { user } = useAuthContext();
  const { anio, cuat } = useConfig();
  const [data, setData] = useState(null);
  const [filename, setFilename] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [cargaHorariaLoading, setCargaHorariaLoading] = useState(true);
  const { cargaHoraria, dispatch } = useCargaHorariaContext();
  const [alert, setAlert] = useState({
    children: null,
    type: "",
    visible: false,
  });

  const reqHeader = {
    headers: {
      Authorization: `Bearer ${user.token}`,
    },
  };

  const showAlert = (children, type) => {
    setAlert({ children, type, visible: true });
  };

  const closeAlert = () => {
    setAlert({ ...alert, visible: false });
  };

  useEffect(() => {
    const fetchCargaHoraria = async () => {
      setCargaHorariaLoading(true);
      try {
        const response = await axios.get(
          `/api/admin/carga-horaria/${anio}/${cuat}`,
          reqHeader
        );
        const cargaHoraria = response.data.map((c) => ({
          nombre: c.docenteInfo.nombre,
          apellido: c.docenteInfo.apellido,
          carga: c.carga,
        }));
        console.log(cargaHoraria);
        dispatch({ type: "SET_CARGA_HORARIA", payload: cargaHoraria });
      } catch (error) {
        showAlert(
          <span>{`Hubo un error al buscar las cargas horarias: ${error.message}`}</span>,
          "error"
        );
      } finally {
        setCargaHorariaLoading(false);
      }
    };
    fetchCargaHoraria();
  }, [anio, cuat, dispatch]);

  const esDNI = (str) => {
    const num = Number(str);
    return Number.isInteger(num) && num > 1000000 && num < 100000000;
  };

  const handleFileChange = (e) => {
    const [selectedFile] = e.target.files;
    if (selectedFile) {
      validateFileContent(selectedFile);
    }
    e.target.value = "";
  };

  const validateFileContent = (file) => {
    setFilename(file.name);
    setData(null);
    const reader = new FileReader();
    reader.onload = (e) => {
      const text = e.target.result;
      const entries = text
        .replace("\r", "")
        .split("\n")
        .map((l) => l.split(","));
      // Se fija si las filas son pares de strings
      if (!entries.every((e) => e.length === 2)) {
        showAlert(
          <span>El archivo .csv no tiene el formato adecuado.</span>,
          "error"
        );
        return;
      }
      // Se fija que ambos strings sean números
      let malos = [];
      for (let [dni, carga] of entries) {
        if (!esDNI(dni) || !esCarga(carga)) {
          malos = [...malos, [dni, carga]];
        }
      }
      if (malos.length > 0) {
        showAlert(
          <>
            <span>Los siguientes renglones tienen algún error:</span>
            <table>
              {malos.map(([dni, carga]) => (
                <tr key={`malo-${dni}-${carga}`}>
                  <td>{dni}</td>
                  <td>{carga}</td>
                </tr>
              ))}
            </table>
          </>,
          "error"
        );
        return;
      }
      const data = entries.map(([docente, dni]) => [
        parseInt(docente, 10),
        parseInt(dni, 10),
      ]);
      setData(data);
      showAlert(
        <span>Las cargas horarias se leyeron correctamente.</span>,
        "success"
      );
    };
    reader.readAsText(file);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsUploading(true);
    try {
      const response = await axios.post(
        `/api/admin/carga-horaria/${anio}/${cuat}`,
        { cargas: data },
        reqHeader
      );
      dispatch({ type: "SET_CARGA_HORARIA", payload: response.data });
      setData(null);
      setFilename(null);
      showAlert(
        <span>Las cargas horarias se subieron correctamente.</span>,
        "success"
      );
    } catch (error) {
      const e =
        error.response && error.response.data
          ? error.response.data
          : error.message;
      showAlert(<span>{e}</span>, "error");
    } finally {
      setIsUploading(false);
    }
  };

  return (
    <div className="importar-carga-horaria">
      <h3>Subir las cargas horarias de los docentes</h3>
      <p>Pasos para subir las cargas horarias de los docentes:</p>
      <ol>
        <li>
          Armar un planilla en Google Sheets con dos columnas, sin encabezado.
          En la primera, poner el DNI del docente. En la segunda, poner la carga
          horaria para este cuatrimestre.
        </li>
        <li>
          Descargar la planilla como un archivo .csv y subirlo a esta página.
        </li>
        <li>
          Si el archivo se procesa correctamente, hacer click en "Importar".
        </li>
      </ol>
      <p>Importante: Este proceso borra las cargas horarias preexistentes.</p>
      <form onSubmit={handleSubmit}>
        <div className="input-file">
          <input
            type="file"
            accept=".csv"
            id="fileInput"
            onChange={handleFileChange}
            style={{ display: "none" }}
          />
          <button
            className="btn"
            id="uploadButton"
            onClick={(e) => {
              e.preventDefault();
              document.getElementById("fileInput").click();
            }}
            disabled={isUploading}
          >
            Subir archivo
          </button>
          <span id="fileName">{filename || "Ningún archivo seleccionado"}</span>
        </div>
        <button className="btn" type="submit" disabled={!data || isUploading}>
          Importar
        </button>
      </form>
      {alert.visible && (
        <Alert type={alert.type} onClose={closeAlert}>
          {alert.children}
        </Alert>
      )}
      <h3>Cargas horarias para este cuatrimestre</h3>
      {cargaHoraria &&
        (cargaHoraria.length === 0 ? (
          <p>Todavía no se subieron cargas horarias para este cuatrimestre.</p>
        ) : (
          <ul>
            {cargaHoraria.sort(compareDocentes).map((c) => (
              <li
                key={`carga-${c.apellido}-${c.nombre}]`}
              >{`${c.apellido}, ${c.nombre}: ${c.carga}`}</li>
            ))}
          </ul>
        ))}
    </div>
  );
};

export default ImportarCargaHoraria;
