import axios from "axios";
import sedes from "../constants/sedes";
import horarios from "../constants/horarios";
import { materiasOfertaHoraria } from "../constants/materias";
import Aula from "../components/Aula";
import { useOfertaHorariaContext } from "../hooks/useOfertaHorariaContext";
import { igualA } from "../context/OfertaHorariaContext";
import { horas } from "../utils/horarios";
import { useState, useEffect } from "react";
import { useConfig } from "../hooks/useConfig";
import { aulasParaOfertaHoraria } from "../utils/aulas";
import { useAuthContext } from "../hooks/useAuthContext";
import TextAlert from "../components/TextAlert";
import { useWindowContext } from "../hooks/useWindowContext";
import { useToast } from "../context/ToastContext";

const Elegir = ({ volver, continuar }) => {
  const { user } = useAuthContext();
  const {
    opciones,
    carga,
    dispatch,
    licencia,
    jubilacion,
    renuncia,
    aumentarDed,
    comentarios,
  } = useOfertaHorariaContext();
  const horasOfrecidas = horas(opciones);
  const { anio, cuat, configsLoading } = useConfig();
  const [aulas, setAulas] = useState(null);
  const [aulasLoading, setAulasLoading] = useState(true);
  const [disabled, setDisabled] = useState(false);
  const materias = materiasOfertaHoraria;
  const { showToast } = useToast();
  const { small } = useWindowContext();

  const reqHeader = {
    headers: {
      Authorization: `Bearer ${user.token}`,
    },
  };

  useEffect(() => {
    const fetchAulas = async () => {
      setAulasLoading(true);
      try {
        const response = await axios.get(
          `/api/aulas/${anio}/${cuat}`,
          reqHeader
        );
        const aulas = aulasParaOfertaHoraria(response.data);
        setAulas(aulas);
      } catch (error) {
        console.error("Error fetching aulas:", error);
      } finally {
        setAulasLoading(false);
      }
    };
    if (!configsLoading) {
      fetchAulas();
    }
  }, [configsLoading, anio, cuat, setAulas]);

  const handleGuardar = async (e) => {
    e.preventDefault();
    if (
      horasOfrecidas === 0 &&
      carga > 0 &&
      !["ADJ", "ASO", "TIT"].includes(user.cargo)
    ) {
      showToast("Todavía no eligió ningún horario", "error");
      return;
    }
    if (
      horasOfrecidas < 2 * carga &&
      !["ADJ", "ASO", "TIT"].includes(user.cargo)
    ) {
      showToast("Debe elegir más horarios", "error");
      return;
    }
    setDisabled(true);
    try {
      const ofertaHoraria = {
        opciones,
        licencia: licencia,
        jubilacion: jubilacion,
        renuncia: renuncia,
        aumentarDed: aumentarDed,
        comentarios: comentarios,
      };
      await axios.post(`/api/horarios/${anio}/${cuat}`, ofertaHoraria, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.token}`,
        },
      });
      dispatch({ type: "GUARDAR", payload: ofertaHoraria });
      continuar();
      window.scrollTo({
        top: 0,
      });
    } catch (error) {
      showToast("Error al guardar los horarios. " + error.message, "error");
    } finally {
      setDisabled(true);
    }
  };

  if (configsLoading || aulasLoading) return null;

  return (
    <div className="elegir-horarios">
      <div className="encuesta-container">
        <h2>Elegir horarios</h2>
        <div>
          <div className="parent">
            <div className="selects">
              <div className="horas-asignadas">Carga horaria: {carga}</div>
              <div className="horas-elegidas">
                Horas ofrecidas: {horasOfrecidas}
              </div>
              <div className="jubilacion">
                <label htmlFor="jubilacion">
                  ¿Está próximo/a a jubilarse?&nbsp;
                </label>
                <select
                  id="jubilacion"
                  value={jubilacion}
                  onChange={(e) => {
                    dispatch({
                      type: "SET_JUBILACION",
                      payload: e.target.value,
                    });
                  }}
                  disabled={disabled}
                >
                  <option value="si">Sí</option>
                  <option value="no">No</option>
                </select>
              </div>
              <div className="licencia">
                <label htmlFor="licencia">
                  ¿Piensa pedir licencia este cuatrimestre?&nbsp;
                </label>
                <select
                  id="licencia"
                  value={licencia}
                  onChange={(e) => {
                    dispatch({
                      type: "SET_LICENCIA",
                      payload: e.target.value,
                    });
                  }}
                  disabled={disabled}
                >
                  <option value="si">Sí</option>
                  <option value="no">No</option>
                </select>
              </div>
              <div className="renuncia">
                <label htmlFor="renuncia">
                  ¿Piensa renunciar a alguno de sus cargos?&nbsp;
                </label>
                <select
                  id="renuncia"
                  value={renuncia}
                  onChange={(e) => {
                    dispatch({
                      type: "SET_RENUNCIA",
                      payload: e.target.value,
                    });
                  }}
                  disabled={disabled}
                >
                  <option value="si">Sí</option>
                  <option value="no">No</option>
                </select>
              </div>
              <div className="aumentar-ded">
                <label htmlFor="aumentarDed">
                  ¿Le gustaría aumentar su dedicación?&nbsp;
                </label>
                <select
                  id="aumentarDed"
                  value={aumentarDed}
                  onChange={(e) => {
                    dispatch({
                      type: "SET_AUMENTARDED",
                      payload: e.target.value,
                    });
                  }}
                  disabled={disabled}
                >
                  <option value="si">Sí</option>
                  <option value="no">No</option>
                </select>
              </div>
            </div>
            <div className="comentarios">
              <div className="comentarios-flex">
                <label htmlFor="comentarios">Comentarios</label>
                <textarea
                  id="comentarios"
                  value={comentarios}
                  onChange={(e) =>
                    dispatch({
                      type: "SET_COMENTARIOS",
                      payload: e.target.value,
                    })
                  }
                  disabled={disabled}
                ></textarea>
              </div>
            </div>
          </div>
          {small || opciones.length === 0
            ? null
            : opciones.length > 0 && (
                <table>
                  <thead>
                    <tr>
                      <th>Sede</th>
                      <th>Materia</th>
                      <th>Horario</th>
                      <th>Preferencia</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {opciones.map(({ sede, materia, horario, pref }) => (
                      <tr key={`elegido-${sede}-${materia}-${horario}`}>
                        <td>{sedes[sede]}</td>
                        <td>{materias[materia]}</td>
                        <td>{horarios[horario]}</td>
                        <td>
                          <select
                            value={pref}
                            onChange={(e) =>
                              dispatch({
                                type: "SET_PREFERENCIA",
                                payload: {
                                  sede,
                                  materia,
                                  horario,
                                  pref: Number(e.target.value),
                                },
                              })
                            }
                            disabled={disabled}
                          >
                            <option value="1">1 - mayor preferencia</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5 - menor preferencia</option>
                          </select>
                        </td>
                        <td
                          className={`eliminar ${disabled ? "disabled" : ""}`}
                          onClick={() =>
                            dispatch({
                              type: "ELIMINAR_HORARIO",
                              payload: { sede, materia, horario },
                            })
                          }
                        >
                          Eliminar
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
          {!small || opciones.length === 0
            ? null
            : opciones.length > 0 && (
                <table>
                  <thead>
                    <tr>
                      <th>Horarios elegidos</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {opciones.map(({ sede, materia, horario, pref }) => (
                      <tr key={`elegido-${sede}-${materia}-${horario}`}>
                        <td>
                          <div className="col">
                            <span>{sedes[sede]}</span>
                            <span>{materias[materia]}</span>
                            <span>{horarios[horario]}</span>
                            <select
                              value={pref}
                              onChange={(e) =>
                                dispatch({
                                  type: "SET_PREFERENCIA",
                                  payload: {
                                    sede,
                                    materia,
                                    horario,
                                    pref: Number(e.target.value),
                                  },
                                })
                              }
                              disabled={disabled}
                            >
                              <option value="1">1 - mayor preferencia</option>
                              <option value="2">2</option>
                              <option value="3">3</option>
                              <option value="4">4</option>
                              <option value="5">5 - menor preferencia</option>
                            </select>
                          </div>
                        </td>
                        <td
                          className={`eliminar ${disabled ? "disabled" : ""}`}
                          onClick={() =>
                            dispatch({
                              type: "ELIMINAR_HORARIO",
                              payload: { sede, materia, horario },
                            })
                          }
                        >
                          Eliminar
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
          <div className="gap-1 mb-2">
            <button
              onClick={() => {
                volver();
                window.scrollTo({
                  top: 0,
                });
              }}
              className="btn"
              disabled={disabled}
            >
              ...Volver
            </button>
            <button onClick={handleGuardar} disabled={disabled} className="btn">
              Guardar y continuar...
            </button>
          </div>
        </div>

        {aulas.map((i) => (
          <div key={`sede-${i.sede}`} className="sede-container">
            <h3>{sedes[i.sede]}</h3>
            <div className="materias-container">
              {i.materias.map((j) => (
                <div
                  key={`materia-${i.sede}-${j.materia}`}
                  className="horarios-container"
                >
                  <h4>{materias[j.materia]}</h4>
                  {j.horarios.map((horario) => (
                    <Aula
                      key={`horario-${i.sede}-${j.materia}-${horario}`}
                      sede={i.sede}
                      materia={j.materia}
                      horario={horario}
                      handleClick={() =>
                        dispatch({
                          type: "AGREGAR_HORARIO",
                          payload: {
                            sede: i.sede,
                            materia: j.materia,
                            horario,
                          },
                        })
                      }
                      disabled={
                        (opciones &&
                          opciones.some(igualA(i.sede, j.materia, horario))) ||
                        disabled
                      }
                    />
                  ))}
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Elegir;
