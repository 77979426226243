import React, { createContext, useReducer } from "react";

const CargaHorariaContext = createContext();

export const cargaReducer = (state, action) => {
  switch (action.type) {
    case "SET_CARGA_HORARIA":
      return action.payload;
    default:
      return state;
  }
};

const CargaHorariaContextProvider = ({ children }) => {
  const [cargaHoraria, dispatch] = useReducer(cargaReducer, null);

  return (
    <CargaHorariaContext.Provider value={{ cargaHoraria, dispatch }}>
      {children}
    </CargaHorariaContext.Provider>
  );
};

export { CargaHorariaContext, CargaHorariaContextProvider };
