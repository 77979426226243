import { createContext, useReducer } from "react";

export const OfertaHorariaContext = createContext();

export const igualA =
  (s, m, h) =>
  ({ sede, materia, horario }) =>
    s === sede && m === materia && h === horario;

export const ofertaHorariaReducer = (state, action) => {
  let sede, materia, horario, pref;
  if (action.payload) {
    sede = action.payload.sede;
    materia = action.payload.materia;
    horario = action.payload.horario;
    pref = action.payload.pref;
  }
  let i;
  let newOpciones;
  switch (action.type) {
    case "SETUP":
      return {
        licencia: "no",
        jubilacion: "no",
        renuncia: "no",
        aumentarDed: "no",
        comentarios: "",
        opciones: [],
        ...action.payload,
      };
    case "AGREGAR_HORARIO":
      if (
        !state.opciones ||
        !state.opciones.some(igualA(sede, materia, horario))
      ) {
        newOpciones = state.opciones
          ? [...state.opciones, { sede, materia, horario, pref: 1 }]
          : [{ sede, materia, horario, pref: 1 }];
      }
      return { ...state, opciones: newOpciones };
    case "ELIMINAR_HORARIO":
      i = state.opciones.findIndex(igualA(sede, materia, horario));
      if (i >= 0) {
        newOpciones = state.opciones.filter(
          (e) => !igualA(sede, materia, horario)(e)
        );
      }
      return { ...state, opciones: newOpciones };
    case "SET_PREFERENCIA":
      i = state.opciones.findIndex(igualA(sede, materia, horario));
      if (i >= 0) {
        newOpciones = state.opciones.map((e) =>
          igualA(sede, materia, horario)(e) ? { ...e, pref } : e
        );
      }
      return { ...state, opciones: newOpciones };
    case "SET_LICENCIA":
      return { ...state, licencia: action.payload };
    case "SET_AUMENTARDED":
      return { ...state, aumentarDed: action.payload };
    case "SET_JUBILACION":
      return { ...state, jubilacion: action.payload };
    case "SET_RENUNCIA":
      return { ...state, renuncia: action.payload };
    case "SET_COMENTARIOS":
      return { ...state, comentarios: action.payload };
    case "GUARDAR":
      return { ...state, horariosElegidos: true, ...action.payload };
    case "ENVIAR":
      return { ...state, ofertaEnviada: true };
    case "LOGOUT":
      return {};
    default:
      return state;
  }
};

export const OfertaHorariaContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(ofertaHorariaReducer, {});

  return (
    <OfertaHorariaContext.Provider value={{ ...state, dispatch }}>
      {children}
    </OfertaHorariaContext.Provider>
  );
};
