const normalizeString = (str) =>
  str
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .toLowerCase();

const compare = (s, t) => {
  if (s > t) {
    return 1;
  } else if (s < t) {
    return -1;
  } else {
    return 0;
  }
};

const compareDocentes = (docente1, docente2) => {
  const c = compare(
    normalizeString(docente1.apellido),
    normalizeString(docente2.apellido)
  );
  if (c !== 0) {
    return c;
  } else {
    return compare(
      normalizeString(docente1.nombre),
      normalizeString(docente2.nombre)
    );
  }
};

export { compareDocentes };
