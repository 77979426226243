import React from "react";
import "../css/alert.css";

const TextAlert = ({ message, type, onClose }) => {
  return (
    <div className={`alert ${type}`}>
      <span className="closebtn" onClick={onClose}>
        ×
      </span>
      <div>{message}</div>
    </div>
  );
};

export default TextAlert;
