const Error = ({ error }) => {
  return (
    <div className="not-found">
      <h2>Error</h2>
      <p>{error}</p>
    </div>
  );
};

export default Error;
