const materias = {
  27: "Álgebra (27)",
  51: "Matemática (51)",
  61: "Matemática (61)",
  62: "Álgebra (62)",
  66: "Análisis (66)",
  71: "Álgebra (71)",
  72: "Análisis (72)",
};

const materiasBis = [
  { id: 27, str: "Álgebra (27)", dias: 3, hs: 3 },
  {
    id: 51,
    str: "Matemática (51)",
    dias: 2,
    hs: 3,
  },
  {
    id: 61,
    str: "Matemática (61)",
    dias: 3,
    hs: 3,
  },
  { id: 62, str: "Álgebra (62)", dias: 3, hs: 3 },
  { id: 66, str: "Análisis (66)", dias: 3, hs: 3 },
  { id: 71, str: "Álgebra (71)", dias: 2, hs: 2 },
  { id: 72, str: "Análisis (72)", dias: 3, hs: 2 },
];

const materiasOfertaHoraria = {
  2762: "Álgebras (27/62)",
  51: "Matemática (51)",
  61: "Matemática (61)",
  66: "Análisis (66)",
  71: "Álgebra (71)",
  72: "Análisis (72)",
};

export { materias, materiasOfertaHoraria };
