import PasosContainer from "../components/PasosContainer";

const PreparandoEncuesta = () => {
  return (
    <>
      <PasosContainer />
      <div className="preparando-container">
        <h2>Encuesta en preparación</h2>
        <p>
          Estamos preparando la encuesta de horarios para el próximo
          cuatrimestre.
        </p>
      </div>
    </>
  );
};

export default PreparandoEncuesta;
