import React, { useEffect, useState } from "react";
import axios from "axios";
import { useAuthContext } from "../hooks/useAuthContext";
import { useConfig } from "../hooks/useConfig";
import { useCargaHorariaContext } from "../hooks/useCargaHorariaContext";
import Alert from "../components/Alert";

const esCarga = (str) => {
  const num = Number(str);
  return str !== "" && Number.isInteger(num) && num >= 0 && num <= 20;
};

const ImportarHorariosAsignados = () => {
  const { user } = useAuthContext();
  const { anio, cuat } = useConfig();
  const [horarios, setHorarios] = useState(null);
  const [filename, setFilename] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [cargaHorariaLoading, setCargaHorariaLoading] = useState(true);
  const { cargaHoraria, dispatch } = useCargaHorariaContext();
  const [alert, setAlert] = useState({
    children: null,
    type: "",
    visible: false,
  });

  const reqHeader = {
    headers: {
      Authorization: `Bearer ${user.token}`,
    },
  };

  const showAlert = (children, type) => {
    setAlert({ children, type, visible: true });
  };

  const closeAlert = () => {
    setAlert({ ...alert, visible: false });
  };

  const esDNI = (str) => {
    const num = Number(str);
    return Number.isInteger(num) && num > 1000000 && num < 100000000;
  };

  function extraerContenidoEntreComillas(str) {
    const regex = /"(.*)"/;
    const match = str.match(regex);
    return match ? match[1] : null;
  }

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = (e) => {
      const arrayBuffer = e.target.result;
      const decoder = new TextDecoder("windows-1252");
      const text = decoder.decode(arrayBuffer);
      const horarios = text
        .replace("\r", "")
        .split("\n")
        .slice(1) // Tira la línea del encabezado
        .filter((l) => l !== "") // Tira las líneas vacías
        .map((l) => l.split(","))
        .filter((l) => extraerContenidoEntreComillas(l[6]) === "Si")
        .map((l) => ({
          anio,
          cuat,
          docente: parseInt(l[0]),
          sede: parseInt(l[1]),
          materia: parseInt(l[2]),
          horario: parseInt(l[3]),
          horas: parseInt(l[5]),
          comentario: extraerContenidoEntreComillas(l[8]),
        }));
      setHorarios(horarios);
    };

    reader.readAsArrayBuffer(file);
    setFilename(file.name);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsUploading(true);
    try {
      const response = await axios.post(
        `/api/admin/horarios-asignados/${anio}/${cuat}`,
        { horarios },
        reqHeader
      );
      setFilename(null);
      showAlert(
        <span>Los horarios asignados se subieron correctamente.</span>,
        "success"
      );
    } catch (error) {
      const e =
        error.response && error.response.data
          ? error.response.data
          : error.message;
      showAlert(<span>{e}</span>, "error");
    } finally {
      setIsUploading(false);
    }
  };

  return (
    <div className="importar-carga-horaria">
      <h3>Subir los horarios asignados</h3>
      <p>
        Una vez distribuidos los horarios, subir a esta página el archivo
        "Elecciones.txt" que produce el TachaTacha.
      </p>
      <p>
        Los docentes podrán ver los horarios que les fueron asignados cuando la
        encuesta esté "Finalizada".
      </p>
      <p>
        Nota: cada vez que se sube un archivo "Elecciones.txt", se reemplazan
        los horarios asignados que pudieran haberse subido con anterioridad.
      </p>
      <form onSubmit={handleSubmit}>
        <div className="input-file">
          <input
            type="file"
            accept=".txt"
            id="fileInput"
            onChange={handleFileChange}
            style={{ display: "none" }}
          />
          <button
            className="btn"
            id="uploadButton"
            onClick={(e) => {
              e.preventDefault();
              document.getElementById("fileInput").click();
            }}
            disabled={isUploading}
          >
            Subir archivo
          </button>
          <span id="fileName">{filename || "Ningún archivo seleccionado"}</span>
        </div>
        <button
          className="btn"
          type="submit"
          disabled={!horarios || isUploading}
        >
          Importar
        </button>
      </form>
      {alert.visible && (
        <Alert type={alert.type} onClose={closeAlert}>
          {alert.children}
        </Alert>
      )}
    </div>
  );
};

export default ImportarHorariosAsignados;
