import sedes from "../constants/sedes";

const compareAulas = (a, b) => {
  const sede = a.sede - b.sede;
  const materia = a.materia - b.materia;
  const horario = a.horario - b.horario;
  if (sede !== 0) {
    return sede;
  } else {
    if (materia !== 0) {
      return materia;
    } else {
      return horario;
    }
  }
};

const compareSedes = (a, b) => {
  return sedes[a].localeCompare(sedes[b]);
};

const aulasParaOfertaHoraria = (aulas) => {
  // Reemplaza la 27 y 62 por la 2762
  const newAulas = aulas.map((a) =>
    a.materia === 27 || a.materia === 62 ? { ...a, materia: 2762 } : a
  );
  const output = [...new Set(newAulas.map((a) => a.sede))]
    .sort(compareSedes)
    .map((sede) => ({
      sede,
    }));
  for (let sede of output) {
    const materias = [
      ...new Set(
        newAulas.filter((a) => a.sede === sede.sede).map((a) => a.materia)
      ),
    ]
      .sort()
      .map((materia) => ({
        materia,
      }));
    sede.materias = materias;
    for (let i = 0; i < sede.materias.length; i++) {
      const horarios = [
        ...new Set(
          newAulas
            .filter(
              (a) =>
                a.sede === sede.sede && a.materia === sede.materias[i].materia
            )
            .map((a) => a.horario)
            .sort()
        ),
      ].sort();
      sede.materias[i].horarios = horarios;
    }
  }
  return output;
};

export { compareAulas, aulasParaOfertaHoraria };
