import React from "react";
import { useToast } from "../context/ToastContext";

const Toast = () => {
  const { toast } = useToast();

  if (!toast) return null;

  return <div className={`toast ${toast.type}`}>{toast.message}</div>;
};

export default Toast;
