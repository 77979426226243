// Saca espacios de los dos extremos y elimina espacios duplicados
const pascalCase = (str) => {
  const pc = (str) =>
    `${str[0].toUpperCase()}${str.substring(1).toLowerCase()}`;
  const cf = (str) => `${str[0].toUpperCase()}${str.substring(1)}`;
  return str
    .trim()
    .split(/[\s,\t,\n]+/)
    .map(pc)
    .map((s) => s.split("'").map(cf).join("'").split("-").map(cf).join("-"))
    .join(" ");
};
// Extraer números
const extraerNumeros = (str) =>
  str
    .split("")
    .map((c) =>
      ["1", "2", "3", "4", "5", "6", "7", "8", "9", "0"].includes(c) ? c : ""
    )
    .join("");

// Convierte el número de cuatrimestre a un string
const getCuat = (i) => {
  if (i === 1) {
    return "1er";
  } else if (i === 2) {
    return "2do";
  }
};

const normalizeString = (
  str // Saca tildes y pasa a minúscula
) =>
  str
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .toLowerCase();

export { pascalCase, extraerNumeros, getCuat, normalizeString };
