import { OfertaHorariaContext } from "../context/OfertaHorariaContext";
import { useContext } from "react";

export const useOfertaHorariaContext = () => {
  const context = useContext(OfertaHorariaContext);

  if (!context) {
    throw Error(
      "useOfertaHorariaContext must be used inside an OfertaHorariaContextProvider."
    );
  }

  return context;
};
