import React from "react";
import { Navigate } from "react-router-dom";
import { useAuthContext } from "../hooks/useAuthContext";

const Home = () => {
  const { user } = useAuthContext();
  if (!user) {
    return <Navigate to="/login" replace />;
  } else {
    return <Navigate to="/encuesta" replace />;
  }
};

export default Home;
