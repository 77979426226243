import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import NotFound from "./NotFound";

// Componente de Ruta Protegida para administradores
const AdminRoutes = ({ user }) => {
  if (!user) {
    return <Navigate to="/" replace />;
  }
  if (user.rol !== "ADMIN") {
    return <NotFound />;
  }
  return <Outlet />;
};

export default AdminRoutes;
