const sedes = {
  2: "Ciudad Universitaria",
  4: "Avellaneda",
  5: "Drago",
  39: "Lugano",
  1: "Estación Buenos Aires",
  15: "Las Heras",
  28: "Moreno",
  10: "Paternal",
  7: "Ramos Mejía",
  6: "San Isidro",
  29: "San Miguel",
};

export default sedes;
