import sedes from "../constants/sedes";
import horarios from "../constants/horarios";
import { materiasOfertaHoraria as materias } from "../constants/materias";

const TablaOpciones = ({ opciones, small, smallTitle }) => {
  return opciones && opciones.length > 0 ? (
    small ? (
      <table>
        <thead>
          <tr>
            <th>{smallTitle}</th>
          </tr>
        </thead>
        <tbody>
          {opciones.map(({ sede, materia, horario, pref }) => (
            <tr key={`elegido-${sede}-${materia}-${horario}`}>
              <td>
                <div className="opciones-small">
                  <span>{sedes[sede]}</span>
                  <span>{materias[materia]}</span>
                  <span>{horarios[horario]}</span>
                  <span>{`Preferencia: ${pref}`}</span>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    ) : (
      <table>
        <thead>
          <tr>
            <th>Sede</th>
            <th>Materia</th>
            <th>Horario</th>
            <th>Preferencia</th>
          </tr>
        </thead>
        <tbody>
          {opciones.map(({ sede, materia, horario, pref }) => (
            <tr key={`elegido-${sede}-${materia}-${horario}`}>
              <td>{sedes[sede]}</td>
              <td>{materias[materia]}</td>
              <td>{horarios[horario]}</td>
              <td>{pref}</td>
            </tr>
          ))}
        </tbody>
      </table>
    )
  ) : null;
};

export default TablaOpciones;
