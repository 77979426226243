import axios from "axios";
import { useAuthContext } from "./useAuthContext";

const useAuthInterceptor = () => {
  const { dispatch } = useAuthContext();

  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response?.data?.error === "Token expired") {
        localStorage.removeItem("user");
        dispatch({ type: "EXPIRE" });
      }
      return Promise.reject(error);
    }
  );
};

export default useAuthInterceptor;
