import { Routes, Route, BrowserRouter } from "react-router-dom";
import { useAuthContext } from "./hooks/useAuthContext";
import { useConfig } from "./hooks/useConfig";
import { Navigate } from "react-router-dom";
import NuevoDocente from "./pages/NuevoDocente";
import EncuestaRoutes from "./pages/EncuestaRoutes";

// pages & components
import Encuesta from "./pages/Encuesta";
import Navbar from "./components/Navbar";
import Login from "./pages/Login";
import DocenteRoutes from "./pages/DocenteRoutes";
import AdminRoutes from "./pages/AdminRoutes";
import VerDocente from "./pages/VerDocente";
import Docentes from "./pages/Docentes";
import useAuthInterceptor from "./hooks/useAuthInterceptor";
import EstadoEncuesta from "./pages/EstadoEncuesta";
import ImportarCargaHoraria from "./pages/ImportarCargaHoraria";
import EditarAulas from "./pages/EditarAulas";
import VerEnvios from "./pages/VerEnvios";
import VerEnvio from "./pages/VerEnvio";
import ArchivosTachaTacha from "./pages/ArchivosTachaTacha";
import ImportarHorariosAsignados from "./pages/ImportarHorariosAsignados";
import LeftPanel from "./pages/LeftPanel";
import Home from "./pages/Home";
import CambiarPassword from "./pages/CambiarPassword";
import NotFound from "./pages/NotFound";
import Toast from "./components/Toast";

function App() {
  const { user, userLoading, expired } = useAuthContext();
  const { configsLoading } = useConfig();
  useAuthInterceptor();

  return userLoading || configsLoading ? null : (
    <div className="App">
      <BrowserRouter>
        <Navbar />
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/" element={<Home />} />
          {/* Rutas protegidas solo para docentes */}
          <Route element={<LeftPanel />}>
            <Route element={<DocenteRoutes user={user} expired={expired} />}>
              <Route path="/encuesta" element={<Encuesta />} />
              <Route path="/cambiar-password" element={<CambiarPassword />} />

              {/* Rutas protegidas solo para admins */}
              <Route element={<AdminRoutes user={user} />}>
                <Route
                  path="/admin/docentes/nuevo"
                  element={<NuevoDocente />}
                />
                <Route path="/admin/docentes/:dni" element={<VerDocente />} />
                <Route path="/admin/docentes" element={<Docentes />} />
                <Route element={<EncuestaRoutes />}>
                  <Route path="/admin/encuesta" element={<EstadoEncuesta />} />
                  <Route
                    path="/admin/encuesta/carga"
                    element={<ImportarCargaHoraria />}
                  />
                  <Route
                    path="/admin/encuesta/horarios-asignados"
                    element={<ImportarHorariosAsignados />}
                  />
                  <Route
                    path="/admin/encuesta/aulas"
                    element={<EditarAulas />}
                  />
                  <Route
                    path="/admin/encuesta/ver-envios"
                    element={<VerEnvios />}
                  />
                  <Route
                    path="/admin/encuesta/:anio/:cuat/:dni"
                    element={<VerEnvio />}
                  />
                  <Route
                    path="/admin/encuesta/tt"
                    element={<ArchivosTachaTacha />}
                  />
                </Route>
              </Route>
            </Route>
            <Route path="*" element={<NotFound />} />
          </Route>
        </Routes>
      </BrowserRouter>
      <Toast />
    </div>
  );
}

export default App;
