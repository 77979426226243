import React from "react";
import "../css/alert.css";

const Alert = ({ children, type, onClose }) => {
  return (
    <div className={`alert ${type}`}>
      <span className="closebtn" onClick={onClose}>
        ×
      </span>
      {children}
    </div>
  );
};

export default Alert;
