import React, { useState, useEffect, useRef } from "react";
import { useConfig } from "../hooks/useConfig";
import { useAulaContext } from "../hooks/useAulaContext";
import { materias } from "../constants/materias";
import horarios from "../constants/horarios";
import sedes from "../constants/sedes";
import axios from "axios";
import Counter from "../components/Counter";
import Alert from "../components/Alert";
import { useAuthContext } from "../hooks/useAuthContext";
import { getCuat } from "../utils/strings";

const EditarAulas = () => {
  const { user } = useAuthContext();
  const { aulas, dispatchAula } = useAulaContext();
  const { anio, cuat, configsLoading } = useConfig();
  const [aulasLoading, setAulasLoading] = useState(true);
  const [newAula, setNewAula] = useState(null);
  const [tempAulas, setTempAulas] = useState(null);
  const [mensajeModal, setMensajeModal] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showCopiar, setShowCopiar] = useState(false);
  const [anteriores, setAnteriores] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [red, setRed] = useState("");
  const [alert, setAlert] = useState({
    children: null,
    type: "",
    visible: false,
  });
  const inputRef = useRef(null);

  const reqHeader = {
    headers: {
      Authorization: `Bearer ${user.token}`,
    },
  };

  const showAlert = (children, type) => {
    setAlert({ children, type, visible: true });
  };

  const closeAlert = () => {
    setAlert({ ...alert, visible: false });
  };

  useEffect(() => {
    if (showModal) {
      inputRef.current.focus();
    }
  }, [showModal]);

  useEffect(() => {
    const fetchAulas = async () => {
      setAulasLoading(true);
      setNewAula({
        anio,
        cuat,
        sede: "0",
        materia: "0",
        horario: "0",
        aulas: "1",
        ocultar: false,
      });
      try {
        const response = await axios.get(
          `/api/aulas/${anio}/${cuat}`,
          reqHeader
        );
        const responseAnteriores = await axios.get(
          `/api/admin/aulas/anteriores/${anio}/${cuat}`,
          reqHeader
        );
        const aulas = response.data;
        dispatchAula({ type: "SET_AULAS", payload: aulas });
        setAnteriores(responseAnteriores.data);
        setTempAulas(
          aulas.map((aula) => ({ _id: aula._id, aulas: aula.aulas }))
        );
      } catch (error) {
        showAlert(
          <span>{`Error al cargar las aulas: ${error.message}`}</span>,
          "error"
        );
      } finally {
        setAulasLoading(false);
      }
    };
    if (!configsLoading) {
      fetchAulas();
    }
  }, [configsLoading, anio, cuat, dispatchAula]);

  if (configsLoading || aulasLoading) return null;

  const handleAgregarAula = async () => {
    try {
      const response = await axios.post("/api/admin/aulas", newAula, reqHeader);
      const aula = response.data;
      dispatchAula({ type: "CREATE_AULA", payload: aula });
      setTempAulas([...tempAulas, { _id: aula._id, aulas: aula.aulas }]);
      showAlert(<span>Aulas agregadas exitosamente.</span>, "success");
    } catch (error) {
      console.log(error);
      let e;
      if (
        error.response &&
        error.response.data &&
        error.response.data.code &&
        error.response.data.code === 11000
      ) {
        e = `Error al agregar aulas: ya existen aulas de ${
          materias[newAula.materia]
        } en la sede y el horario elegidos.`;
      } else {
        e = "Error al agregar aulas : " + error.message;
      }
      showAlert(<span>{e}</span>, "error");
    }
  };

  const handleEliminarAula = (aula) => () => {
    setRed(aula._id);
    setMensajeModal(
      <>
        <p>Está por eliminar el aula:</p>
        <ul>
          <li>{sedes[aula.sede]}</li>
          <li>{materias[aula.materia]}</li>
          <li>{horarios[aula.horario]}</li>
        </ul>
      </>
    );
    setShowModal(true);
  };

  const handleGuardar = (aula_id) => async () => {
    const aulas = tempAulas.find((aula) => aula._id === aula_id).aulas;
    try {
      const response = await axios.put(
        "/api/admin/aulas/" + aula_id,
        {
          aulas,
        },
        reqHeader
      );
      dispatchAula({ type: "UPDATE_AULA", payload: response.data });
      showAlert(<span>Aulas modificadas exitosamente.</span>, "success");
    } catch (error) {
      showAlert(
        <span>{`Error al modificar las aulas: ${error.message}`}</span>,
        "error"
      );
    } finally {
      setRed("");
    }
  };

  const handleConfirm = async (e) => {
    e.preventDefault();
    if (inputValue === "eliminar") {
      setShowModal(false);
      setInputValue("");
      setMensajeModal(null);
      try {
        const response = await axios.delete(
          "/api/admin/aulas/" + red,
          reqHeader
        );
        dispatchAula({ type: "DELETE_AULA", payload: response.data });
        setTempAulas(tempAulas.filter((aula) => aula._id !== red));
        showAlert(<span>Aulas eliminadas exitosamente.</span>, "success");
      } catch (error) {
        showAlert(
          <span>{`Error al eliminar las aulas: ${error.message}`}</span>,
          "error"
        );
      } finally {
        setRed("");
      }
    } else {
      window.alert(`Por favor, escriba "eliminar" para confirmar.`);
    }
  };
  const handleCloseModal = () => {
    setRed("");
    setInputValue("");
    setShowModal(false);
  };

  const handleCopiar = async (e) => {
    e.preventDefault();
    const sel = document.getElementById("anterior");
    const a = Number(sel.value.slice(0, 4));
    const c = Number(sel.value.slice(5, 6));
    try {
      const response = await axios.put(
        `/api/admin/aulas/copiar/${a}/${c}`,
        { anioActual: anio, cuatActual: cuat },
        reqHeader
      );
      dispatchAula({ type: "SET_AULAS", payload: response.data });
      setTempAulas(tempAulas.filter((aula) => aula._id !== red));
      showAlert(<span>Aulas copiadas exitosamente.</span>, "success");
    } catch (error) {
      showAlert(
        <span>{`Error al copiar las aulas: ${error.message}`}</span>,
        "error"
      );
    } finally {
      setShowCopiar(false);
    }
  };

  return (
    <>
      <div className="editar-aulas">
        <div>
          <button
            className="btn"
            onClick={() => {
              setShowCopiar(true);
            }}
            disabled={showModal || red || showCopiar}
          >
            Copiar aulas de un cuatrimestre anterior
          </button>
        </div>
        <h3>Aulas disponibles</h3>
        {alert.visible && (
          <Alert type={alert.type} onClose={closeAlert}>
            {alert.children}
          </Alert>
        )}
        {aulas && (
          <table>
            <thead>
              <tr>
                <th>Sede</th>
                <th>Materia</th>
                <th>Horario</th>
                <th>Aulas</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <select
                    id="select-sede"
                    onChange={(e) => {
                      setNewAula({ ...newAula, sede: e.target.value });
                    }}
                    disabled={showModal || red || showCopiar}
                  >
                    <option value="0">Elegir sede...</option>
                    {Object.entries(sedes).map(([i, nombre]) => (
                      <option key={`option-${i}`} value={i}>
                        {nombre}
                      </option>
                    ))}
                  </select>
                </td>
                <td>
                  <select
                    id="select-materia"
                    onChange={(e) => {
                      setNewAula({ ...newAula, materia: e.target.value });
                    }}
                    disabled={showModal || red || showCopiar}
                  >
                    {" "}
                    <option value="0">Elegir materia...</option>
                    {Object.entries(materias).map(([i, nombre]) => (
                      <option key={`option-${i}`} value={i}>
                        {nombre}
                      </option>
                    ))}
                  </select>
                </td>
                <td>
                  <select
                    id="select-horario"
                    onChange={(e) => {
                      setNewAula({ ...newAula, horario: e.target.value });
                    }}
                    disabled={showModal || red || showCopiar}
                  >
                    {" "}
                    <option value="0">Elegir horario...</option>
                    {Object.entries(horarios).map(([i, nombre]) => (
                      <option
                        key={`option-${i}`}
                        value={i}
                      >{`${i} - ${nombre}`}</option>
                    ))}
                  </select>
                </td>
                <td>
                  <Counter
                    value={1}
                    disabled={showModal || red || showCopiar}
                    onChange={(v) => {
                      setNewAula({ ...newAula, aulas: Number(v) });
                    }}
                  />
                </td>
                <td>
                  <button
                    className="btn"
                    disabled={
                      newAula.sede === "0" ||
                      newAula.materia === "0" ||
                      newAula.horario === "0" ||
                      newAula.aulas <= 0 ||
                      showModal ||
                      red ||
                      showCopiar
                    }
                    onClick={handleAgregarAula}
                  >
                    Agregar
                  </button>
                </td>
              </tr>
              {!configsLoading &&
                !aulasLoading &&
                aulas.map((aula) => (
                  <tr
                    key={`td-${aula._id}`}
                    className={red === aula._id ? "red" : ""}
                  >
                    <td>{sedes[aula.sede]}</td>
                    <td>{materias[aula.materia]}</td>
                    <td>{horarios[aula.horario]}</td>
                    <td>
                      <Counter
                        value={aula.aulas}
                        disabled={
                          showModal || (red && aula._id !== red) || showCopiar
                        }
                        onChange={(v) => {
                          const n = Number(v);
                          setTempAulas(
                            tempAulas.map((a) =>
                              a._id === aula._id ? { ...a, aulas: n } : a
                            )
                          );
                          if (aula.aulas !== n) {
                            setRed(aula._id);
                          } else {
                            setRed("");
                          }
                        }}
                      />
                    </td>
                    <td>
                      <button
                        className="btn"
                        onClick={handleGuardar(aula._id)}
                        disabled={
                          showModal ||
                          (red && aula._id !== red) ||
                          !red ||
                          showCopiar
                        }
                      >
                        Guardar
                      </button>
                      <button
                        className="btn"
                        onClick={handleEliminarAula(aula)}
                        disabled={showModal || red || showCopiar}
                      >
                        Eliminar
                      </button>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        )}
      </div>
      {showModal && (
        <div className="modal">
          <div className="title">
            <h3>Eliminar aula</h3>
            <button className="close-button" onClick={handleCloseModal}>
              ×
            </button>
          </div>
          {mensajeModal}
          <form onSubmit={handleConfirm}>
            <input
              type="text"
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
              placeholder={`Escriba "eliminar"`}
              ref={inputRef}
            />
            <button className="btn" type="submit">
              Confirmar
            </button>
          </form>
        </div>
      )}
      {showCopiar && (
        <div className="modal">
          <div className="title">
            <h3>Copiar aulas de un cuatrimestre anterior</h3>
            <button
              className="close-button"
              onClick={() => {
                setShowCopiar(false);
              }}
            >
              ×
            </button>
          </div>
          {`Esta acción borrará todos las aulas del cuatrimestre actual y las reemplazará por las del cuatrimestre elegido.`}
          <form onSubmit={handleCopiar}>
            <select id="anterior">
              {anteriores.map(([anio, cuat]) => (
                <option value={`${anio}-${cuat}`}>{`${anio} - ${getCuat(
                  cuat
                )} cuatrimestre`}</option>
              ))}
            </select>
            <button className="btn" type="submit">
              Copiar
            </button>
          </form>
        </div>
      )}
    </>
  );
};

export default EditarAulas;
