import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useConfig } from "../hooks/useConfig";
import Error from "./Error";

// Componente de Ruta Protegida para docentes
const DocenteRoutes = ({ user }) => {
  const { anio, cuat } = useConfig();

  if (!user) {
    return <Navigate to="/login" replace />;
  }
  if (anio === undefined || cuat === undefined) {
    return (
      <Error error="Hubo un error al cargar la configuración. Intentelo nuevamente más tarde." />
    );
  }

  return <Outlet />;
};

export default DocenteRoutes;
