import { WindowContext } from "../context/WindowContext";
import { useContext } from "react";

export const useWindowContext = () => {
  const context = useContext(WindowContext);

  if (!context) {
    throw Error("useWindowContext debe usarse dentro de WindowContextProvider");
  }

  return context;
};
