import { useConfig } from "../hooks/useConfig";

const Inicio = ({ continuar }) => {
  const { anio, cuat, encuestaCierra } = useConfig();
  const handleClick = () => {
    continuar();
    window.scrollTo({
      top: 0,
    });
  };
  return (
    <div className="inicio-container">
      <h2>Inicio</h2>
      <p>
        {anio &&
          cuat &&
          encuestaCierra &&
          `La encuesta de horarios para el ${
            cuat === 1 ? "primer" : "segundo"
          } cuatrimestre de ${anio} estará abierta hasta el ${encuestaCierra} a las 23:59 h.`}
      </p>
      <p>
        Los horarios que visualiza son los que están disponibles en este
        período.
      </p>
      <p>Indicaciones a tener en cuenta al llenar la encuesta:</p>
      <ul>
        <li>
          Tiene que ofertar al menos el doble de las horas a realizar en el
          cuatrimestre. Para esto no cuentan las materias sino los días,
          horarios y sedes.
        </li>
        <li>
          Todos los horarios que ofrece tienen que ser posibles. Por favor, no
          elegir un horario y comentar en las observaciones que en realidad no
          puede cumplir sus horas ahí. En caso contrario se le volverán a pedir
          horarios dentro de los que queden posibles en el momento de la
          distribución.
        </li>
        <li>
          De ser necesario, se considerará que puede dictar otras materias del
          área dentro de los horarios y sedes elegidos.
        </li>
      </ul>
      <button onClick={handleClick} className="btn">
        Empezar...
      </button>
    </div>
  );
};

export default Inicio;
